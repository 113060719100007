import React from 'react'
import { cloudinary_upload_url } from "../config/config";

const uploadImagesToCloudinary = async (imagesArray, cloudinary_preset, validation_callback) => {
  if (!validation_callback()) {
    return imagesArray;
  }

  const apiUrl = cloudinary_upload_url;

  const urls = [];
  for (const fileUrl of imagesArray) {
    const data = {
      "file": fileUrl,
      "upload_preset": cloudinary_preset,
    };

    try {
      const result = await fetch(apiUrl, {
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST',
      });

      const imageData = await result.json();
      urls.push(imageData.secure_url);
    } catch (e) {
      console.log(e);
    }
  }

  return urls;
};

const filterImage = (event) => {
  let images = [];
  for (var i = 0; i < event.target.files.length; i++) {
    images[i] = event.target.files.item(i);
  }
  images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif)$/))
  let message = `${images.length} valid image(s) selected`
  console.log(message)
  return images.length ? images[0] : undefined
}

const imageToBase64 = (image) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(image);
  });
}

const selectImage = (event, callback) => {
  const images = [...event.target.files];
  const promises = images.map(image => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(image);
    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = reject;
  }));

  Promise.all(promises)
    .then(base64List => {
      // Si callback
      console.log('selected base 64')
      console.log(base64List)
      callback(base64List);
    })
    .catch(error => {
      console.error(error);
    });
};







/*const selectImage = (event, callback) => {
  const image = filterImage(event);
  if (image) {
    imageToBase64(image).then(base64 => {
      callback(base64);
    }).catch(error => {
      console.error(error);
    });
  }
}*/

/*
const imageToBase64 = (imgUrl, callback) => {
  let fileReader = new FileReader()
  fileReader.onloadend = () => {
    callback(fileReader)
    //this.setState({ imgMenu: fileReader.result })
  }
  fileReader.readAsDataURL(imgUrl)
}

const selectImage = (event, callback, state) => {
  let result = filterImage(event)
  if (result)
    imageToBase64(result, callback)
}
*/

export { uploadImagesToCloudinary, imageToBase64, filterImage, selectImage }