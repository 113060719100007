import { gql } from "@apollo/client";

export const CREATE_MEMBERSHIP = gql`
  mutation CreateMembership($inputMembership: MembershipInput!) {
    createMembership(inputMembership: $inputMembership) {
      _id
      user {
        _id
        name
        phone
        email
        password
        picture
        is_active
        createdAt
        updatedAt
        notificationToken
        stripe_user_id
        stripe_ephemeral_key
      }
      plan {
        duration
        description
        images_qty
        properties_qty
        is_deleted
        createdAt
        updatedAt
      }
      startDate
      endDate
      status
    }
  }
`;

export const GET_MEMBERSHIPS = gql`
  query Memberships($user: ID) {
    memberships(user: $user) {
      _id
      user {
        _id
        name
        phone
        email
        password
        picture
        is_active
        createdAt
        updatedAt
        notificationToken
        stripe_user_id
        stripe_ephemeral_key
      }
      payment {
        _id
        user {
          _id
          name
        }
        amount
        status
      }
      plan {
        duration
        description
        images_qty
        properties_qty
        is_deleted
        createdAt
        updatedAt
      }
      startDate
      endDate
      status
    }
  }
`;


export const DELETE_MEMBERSHIP = gql`
  mutation DeleteMembership($id: ID!) {
    deleteMembership(id: $id) {
      _id
      user {
        _id
        name
        phone
        email
        password
        picture
        is_active
        createdAt
        updatedAt
        notificationToken
        stripe_user_id
        stripe_ephemeral_key
      }
      plan {
        duration
        description
        images_qty
        properties_qty
        is_deleted
        createdAt
        updatedAt
      }
      startDate
      endDate
      status
    }
  }
`;


export const UPDATE_MEMBERSHIP = gql`
  mutation UpdateMembership($id: ID!, $inputMembership: MembershipInput!) {
    updateMembership(id:$id,input:$inputMembership) {
      _id
      user {
        _id
        name
        phone
        email
        password
        picture
        is_active
        createdAt
        updatedAt
        notificationToken
        stripe_user_id
        stripe_ephemeral_key
      }
      plan {
        duration
        description
        images_qty
        properties_qty
        is_deleted
        createdAt
        updatedAt
      }
      startDate
      endDate
      status
    }
  }
`;



export const MEMBERSHIP_CHECK_LIMITS = gql`
  query MembershipCheckLimits($userId:ID!) {
    membershipCheckLimits(userId: $userId) {
      totalProperties
      propertiesLimit
      canCreateProperties
      membershipActive
    }
  }
`;