import { React, useState } from "react";
//import { withTranslation } from 'react-i18next';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Col
} from "reactstrap";

//import { redirect } from "react-router-dom";

function Cancelaciones() {
  
  return (
    <>
      <Col lg="12" md="12">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted mt-2 mb-3">
              <h2>Instrucciones de Cancelación de Cuenta en App</h2>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <p>
              Si deseas cancelar tu cuenta de Noveporta, puedes hacerlo fácilmente a través de la aplicación siguiendo estos pasos:
            </p>
            <ol>
              <li>
                Abre la aplicación y accede al menú lateral izquierdo.
              </li>
              <li>
                Selecciona la opción <strong>“Cancelar Cuenta”</strong>.
              </li>
              <li>
                Haz clic en el botón <strong>“Confirmar Cancelación”</strong>.
              </li>
            </ol>
            <p>
              Es importante tener en cuenta que la cancelación de la cuenta es irreversible. Una vez que confirmes la cancelación, tu cuenta será revisada para eliminar cualquier suscripción o pago pendiente, lo cual puede tardar hasta <strong>30 días</strong>. Durante este período, se procederá también a la eliminación de tus datos personales asociados a la cuenta.
            </p>
            <p>
              Si prefieres solicitar la cancelación a través de correo electrónico, también puedes contactarnos en <a href="mailto:cancelaciones@noveporta.com">cancelaciones@noveporta.com</a>, indicando tu nombre de usuario y detalles de la cuenta.
            </p>
            <p>
              <strong>Nota:</strong> Una vez que tu cuenta haya sido cancelada, no será posible recuperarla ni los datos asociados a ella.
            </p>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

//export default withTranslation()(Cancelaciones);
export default Cancelaciones;
