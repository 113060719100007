// AuthProvider.jsx
// This component provides authentication service 
// for the application. 

import React from "react";
import {
    useNavigate,
    useLocation
} from 'react-router-dom';


const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {

  const [token, setToken] = React.useState(null);  
  const navigate = useNavigate(); 
  const location = useLocation();

  const login = async (data) => {
    localStorage.setItem("zoom-user", JSON.stringify(data.adminLogin))
    setToken(data.token)
    const originRef = location.state?.from?.pathname || '/admin/dashboard';
    navigate(originRef);
  }

  const checkLogin = () => {
    return localStorage.getItem("zoom-user")
  }

  /*const handleLogin = async () => {
    const token = localStorage.getItem("zoom-user") ?? null //await fakeAuth();
    setToken(token);
    const originRef = location.state?.from?.pathname || '/admin/dashboard';
    navigate(originRef);
  };*/

  const logout = () => {
    localStorage.removeItem("zoom-user")
    setToken(null);
    navigate('/auth/login')
    console.log('sali')
  };

  const value = {
    token,
    login: login,
    checkLogin: checkLogin,
    logout: logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
    return React.useContext(AuthContext);
};

export {AuthProvider, useAuth};

