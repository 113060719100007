import React, { useState, useEffect } from 'react';
import {
  Card,
  Upload,
  CardBody,
  CardHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Modal,
  Switch,
  Alert
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { withTranslation } from 'react-i18next';
import { Query, Mutation } from '@apollo/client/react/components';
import { gql, compose, withApollo } from '@apollo/client';

import { createCharacteristic, getCharacteristics, editCharacteristic } from '../../apollo/server';
//import OptionsComponent from '../Option/Option';
import { validateFunc } from '../../constraints/constraints'
import { uploadImagesToCloudinary, imageToBase64, filterImage, selectImage } from '../../utils/image'
import { cloudinary_upload_url, cloudinary_characteristics_icons } from "../../config/config";
import { setSelectionRange } from '@testing-library/user-event/dist/utils';

const GET_CHARACTERISTICS = gql`${getCharacteristics}`
const CREATE_CHARACTERISTIC = gql`${createCharacteristic}`
const EDIT_CHARACTERISTIC = gql`${editCharacteristic}`

/*const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });*/
export default function Characteristic(props) {
  
  const [imagesBase64Array, setImagesBase64Array] = useState(null)

  const [characteristic, setCharacteristic] = useState({
    title: '',
    icon: '',
    is_enabled: true,
    titleError: false,
    iconError: false,
  })
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  //const [characteristicIndex, setCharacteristicIndex] = useState(0)


  useEffect(() => {
    if (props.characteristic) {
      setCharacteristic({
        title: props.characteristic.title,
        icon: props.characteristic.icon,
        is_enabled: props.characteristic.is_enabled,
        titleError: false,
        iconError: false
      })
    }
  }, [props.characteristic])

 
  const onChange = (event, index, state) => {
    setCharacteristic({
      ...characteristic,
      title: event.target.value
    })
  }
  const onBlur = (index, state) => {
    //const characteristics = state.characteristics
    /*if (state === "title")
        characteristics[index]['titleError'] = !!validateFunc({ characteristicTitle: characteristics[index][state] }, 'characteristicTitle')
    if (state === "description")
        characteristics[index]['descriptionError'] = !!validateFunc({ characteristicDescription: characteristics[index][state] }, 'characteristicDescription')
    if (state === "quantity_minimum") {
        characteristics[index]['quantity_minimumError'] = !!validateFunc({ characteristicQuantityMinimum: characteristics[index][state] }, 'characteristicQuantityMinimum')
        characteristics[index]['quantity_minimumError'] = characteristics[index]['quantity_minimumError'] || characteristics[index]['quantity_minimum'] > characteristics[index]['quantity_maximum']
    }
    if (state === "quantity_maximum") {
        characteristics[index]['quantity_maximumError'] = !!validateFunc({ characteristicQuantityMaximum: characteristics[index][state] }, 'characteristicQuantityMaximum')
        characteristics[index]['quantity_maximumError'] = characteristics[index]['quantity_maximumError'] || characteristics[index]['quantity_maximum'] < characteristics[index]['quantity_minimum']
    }
    if (state === "options") {
        characteristics[index]['optionsError'] = characteristics[index]['options'].length === 0
    }
    setState({ characteristics })*/
  }
  const onSelectOption = (index, id) => {
    /*const characteristics = state.characteristics
    const option = characteristics[index].options.indexOf(id)
    if (option < 0)
        characteristics[index].options.push(id)
    else
        characteristics[index].options.splice(option, 1)
    setState({ characteristics })*/
  }
  const updateOptions = (ids) => {
    /*console.log(state.characteristicIndex, ids)
    const characteristics = state.characteristics
    characteristics[state.characteristicIndex].options = characteristics[state.characteristicIndex].options.concat(ids)
    setState({ characteristics })*/
  }
  const onAdd = (index) => {
    /*const characteristics = state.characteristics
    if (index === characteristics.length - 1)
        characteristics.push({ title: '', description: '', quantity_minimum: 0, quantity_maximum: 1, options: [] })
    else
        characteristics.splice(index + 1, 0, { title: '', description: '', quantity_minimum: 0, quantity_maximum: 1, options: [] });
    setState({ characteristics })*/
  }
  const onRemove = (index) => {
    /*if (state.characteristics.length === 1 && index === 0) {
        return
    }
    const characteristics = state.characteristics
    characteristics.splice(index, 1);
    setState({ characteristics })*/
  }
  const toggleModal = (index) => {
    //console.log(index)
    //setState({ optionsModal: !state.optionsModal, characteristicIndex: index })
    //setOptionsModal({ optionsModal: !state.optionsModal, characteristicIndex: index })
  }
  const validate = () => {
    //const characteristics = state.characteristics
    /*characteristics.map((characteristic, index) => {
      onBlur(index, 'title')
      onBlur(index, 'description')
      onBlur(index, 'quantity_minimum')
      onBlur(index, 'quantity_maximum')
      onBlur(index, 'options')
      return characteristic
    })
    const error = characteristics.filter(characteristic => characteristic.titleError || characteristic.descriptionError || characteristic.quantity_minimumError || characteristic.quantity_maximumError || characteristic.optionsError)
    if (!error.length)
      return true
    return false*/
    return true
  }
  const onCompleted = ({ createCharacteristic, editCharacteristic }) => {
    if (createCharacteristic) {
      setCharacteristic({
        title: '',
        titleError: '',
        icon: '',
        is_enabled: true
      })

      setSuccess('Guardado')
      setError('')
    }

    if (editCharacteristic) {
      setSuccess('Guardado')
      setError('')
    }
  }
  const onError = (error) => {
    setSuccess('')
    setError('Un error ocurrió en el guardado, intenta nuevamente. ')
    //setState({ error: 'An error occured while saving,Try again', success: '' })
  }
  // This is a function that is called when a mutation is called
const update = (proxy, { data: { createCharacteristics } }) => {
  // Try to run the following code
    try {
      // If the mutation is successful
      if (createCharacteristics) {
        // Read the query that is being used
        const data = proxy.readQuery({ query: GET_CHARACTERISTICS });
        // Add the new data to the query
        data.characteristics = data.characteristics.concat(createCharacteristics);
        // Write the new data to the query
        proxy.writeQuery({ query: GET_CHARACTERISTICS, data });
        // If the updatecharacteristicsList function is available
        if (props.updatecharacteristicsList)
          // Call the updatecharacteristicsList function and pass in the new data
          props.updatecharacteristicsList(createCharacteristics.map(({ _id }) => _id))
      }
    } catch (error) {
      // If there is an error, log it to the console
      console.error(error);
    }
  }

  const onDismiss = () => {
    setSuccess('')
    setError('')
  }

  const getUrls = async () => {
    const images_url =   await uploadImagesToCloudinary(
      imagesBase64Array,
      cloudinary_characteristics_icons,
      () => imagesBase64Array.length > 0  ? true : false
    )
    //console.log('resultado de cloudiay')
    //console.log(images_url[0])
    // Retorna un solo Url String
    return await images_url.length > 0 ? images_url[0] : ''
  }

  return (
    <Card title={props.characteristic ? null : "Crear Característica"}>
      <Form>
        <Input.Group size="large">
          <Row gutter={24}>
            <Col span={8} >

              <label className="form-control-label" htmlFor="input-title">
                Título
              </label>

              <Input
                //className="form-control-alternative"
                id="input-title"
                placeholder="e.g Baños o Recámaras"
                type="text"
                value={characteristic.title}
                onChange={(event) => {
                  onChange(event, 'title')
                }}
                //onBlur={event => { onBlur('title') }} 
                />

            </Col>
            <Col span={8} >

              <label className="form-control-label" htmlFor="input-title">
                Ícono
              </label>
              <div >
                {characteristic.icon && typeof characteristic.icon === 'string' &&
                  <a href="#zoom" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      className="picture-circle icon-image-upload"
                      src={characteristic.icon} />
                  </a>}
                  <input
                    className="mt-4"
                    type="file"
                    onChange={(event) => {
                      selectImage(event, (base64List) => { // Callback para modificar el estado de la imagen del iconos
                        
                        console.log('fileReader desde onchange en input')
                        console.log(base64List)
                        // Base 64 preloaded images, before submit
                        setImagesBase64Array(base64List)

                        /*setCharacteristic({
                          ...characteristic,
                          icon: fileReader
                        })*/
                      })
                    }} />
              </div>

            </Col>

            <Col span={8} >
              
              <label className="form-control-label" htmlFor="input-title">
                Activo
              </label>
              
            <div>
                <Switch checked={characteristic.is_enabled} onChange={(checked) => {
                console.log(`switch to ${checked}`)
                setCharacteristic({
                  ...characteristic,
                  is_enabled: checked
                })
              }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col gutter={24}>

              <Mutation mutation={props.characteristic ? EDIT_CHARACTERISTIC : CREATE_CHARACTERISTIC}
                onCompleted={onCompleted}
                onError={onError}
                update={update}
                refetchQueries={[{ query: GET_CHARACTERISTICS }]}>
                {(mutate, { loading }) => {
                  if (loading) return (<Button disabled color="success" size="lg" block>Guardando</Button>)
                  return (< Button
                    type="primary" size="lg" 
                    block
                    onClick={async () => {
                      if (validate())
                        mutate({
                          variables: {
                            characteristicInput: {
                              _id: props.characteristic ? props.characteristic._id : '',
                              title: characteristic.title,
                              is_enabled: characteristic.is_enabled,
                              icon: await getUrls()
                              
                            }
                          }
                        })
                    }}> Guardar</Button>)
                }}
              </Mutation>

            </Col>
            
          </Row>
          <Row>
            <Col gutter={24}>
              {!!success && <Alert  type="success"  onClose={onDismiss} message={success} />}
              {!!alert && <Alert  type="danger"  onClose={onDismiss} message={error} />}
            </Col>
          </Row>
        </Input.Group>
      </Form>
    </Card>

  )

}
