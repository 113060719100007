import { React } from 'react';
import AdminLayout from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";
import PublicLayout from "./layouts/Public.jsx";
import { AuthProvider } from "./components/Auth/AuthProvider.jsx";
import Login from "./views/Login.jsx";
import Dashboard from "./views/Dashboard.jsx";
import Characteristic from "./views/Characteristic.jsx";
import Property from "./views/Property.jsx";
import Membership from "./views/Membership";
import Plan from "./views/Plan.jsx";
import Users from "./views/Users.jsx";
import Home from "./views/Home.jsx";
import Cancelaciones from "./views/Cancelaciones.jsx";
import Privacidad from "./views/Privacidad.jsx";

import { ProtectedRoute } from "./views/ProtectedRoute";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import './App.css';

function App() {
  // console.log('app render')
  return (
    <BrowserRouter>
      <AuthProvider>
          <Routes>
            <Route element={<PublicLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/cancelaciones" element={<Cancelaciones />} />
              <Route path="/privacidad" element={<Privacidad />} />
            </Route>
            <Route element={<AuthLayout />}>
              <Route path="/auth/login" element={<Login />} />
            </Route>
            <Route element={<ProtectedRoute><AdminLayout /></ProtectedRoute>}>
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/characteristic" element={<Characteristic/>} />
              <Route path="/admin/property" element={<Property />} />
              <Route path="/admin/membership" element={<Membership />} />
              <Route path="/admin/plan" element={<Plan />} />
              <Route path="/admin/users" element={<Users />} />
            </Route>
            <Route path="/" element={<Navigate from="/" to="/auth/login" />} />
          </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
