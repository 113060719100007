import { React, useState } from "react";
//import { withTranslation } from 'react-i18next';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Col,
  UncontrolledAlert,
  Alert
} from "reactstrap";

//import { redirect } from "react-router-dom";
import { Mutation } from '@apollo/client/react/components';
import { gql, useMutation } from '@apollo/client';
import { adminLogin } from "../apollo/server";
import { validateFunc } from '../constraints/constraints';
import { useAuth } from '../components/Auth/AuthProvider'

const LOGIN = gql`${adminLogin}`

function Login() {

  const { login } = useAuth();


  const [fields, setFields ] = useState({
    email: "admin@noveporta.com",
    password: "Esp#Rem#201",
    emailError: null,
    passwordError: null,
    error: null,
    //redirectToReferrer: localStorage.getItem("zoom-user") ? true : false // TODO usar useAuth fun en AuthContext
  })
  const validate = () => {
    let emailError = !validateFunc({ email: fields.email }, "email")
    let passwordError = !validateFunc({ password: fields.password }, "password")
    setFields({
      ...fields,
      emailError: emailError,
      passwordError: passwordError
    })
    console.log(emailError)
    console.log(passwordError)
    return emailError && passwordError
  }

  const handleBlur = (e, field) => {
    setFields({
      ...fields,
      [field + 'Error']: !validateFunc({ [field]: fields[field] }, field)
    })
  }
  // console.log(fields)
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Introduce tus datos de acceso</small>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5"></CardBody>
          <CardBody className="px-lg-5 py-lg-5">
           
            <Form role="form">
              <FormGroup className={fields.emailError === null ? "" : fields.emailError ? "has-success" : "has-danger"}>
                <InputGroup className="input-group-alternative">

                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>

                  <Input
                    value={fields.email}
                    onChange={event => {
                      setFields({
                        ...fields,
                        email: event.target.value,
                        emailError: null,
                        passwordError: null
                      })
                    }}
                    onBlur={event => { handleBlur(event, 'email') }}
                    placeholder="Email"
                    type="email" />
                </InputGroup>
              </FormGroup>
              <FormGroup className={fields.passwordError === null ? "" : fields.passwordError ? "has-success" : "has-danger"}>
                <InputGroup className="input-group-alternative">

                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>

                  <Input
                    value={fields.password}
                    onChange={event => {
                      setFields({
                        ...fields,
                        password: event.target.value
                      })
                    }}
                    onBlur={event => { handleBlur(event, 'password') }}
                    placeholder="Password"
                    type="password" />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Mutation
                  mutation={LOGIN}
                  onCompleted={(data) => {
                    // In Auth Context
                    login(data)
                    //localStorage.setItem("zoom-user", JSON.stringify(data.adminLogin))
                    setFields({
                      ...fields,
                      redirectToReferrer: true,
                      emailError: null,
                      passwordError: null
                    })
                  }}
                  onError={error => {
                    setFields({
                      ...fields,
                      error: error.networkError.result.errors[0].message,
                      emailError: null,
                      passwordError: null
                    })
                  }}
                >
                  {(adminLogin, { loading, error }) => {

                    return (
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={() => {
                          setFields({
                            ...fields,
                            emailError: null,
                            passwordError: null
                          })
                          console.log('NO validate')
                          if (validate()) {
                            console.log('validate ok')
                            adminLogin({ variables: { ...fields } })
                          }
                        }}>
                        Accesar
                      </Button>
                    )
                  }}
                </Mutation>
              </div>
              {fields.error &&
                <UncontrolledAlert color="warning" fade={true}>
                  <span className="alert-inner--text">
                    {fields.error}
                  </span>
                </UncontrolledAlert>}
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}
//export default withTranslation()(Login);
export default Login;
