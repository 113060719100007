//const { t, i18n } = useTranslation();
import React, { useState, useEffect } from 'react';
import { Layout, Table, Card, Row, Modal, Dropdown, Space, Button, Tag, Spin } from 'antd'
import { ExclamationCircleOutlined, EllipsisOutlined, DownOutlined, SmileOutlined, CheckCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'
import { LoadScript } from "@react-google-maps/api";
//import Header from "../components/Headers/Header.jsx";
import PropertyComponent from '../components/Property/Property';
import { getProperties, deleteProperty } from '../apollo/server';
//import { withTranslation } from 'react-i18next';
//import { Query, Mutation } from '@apollo/client/react/components';
import { gql, useMutation, useQuery } from '@apollo/client';

//import { transformToNewline } from '../utils/stringManipulations'

const GET_PROPERTIES = gql`${getProperties}`
const DELETE_PROPERTY = gql`${deleteProperty}`

export default function Property(props) {

    const [property, setProperty] = useState(null)
    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    //const [mapsApiLoaded, setMapsApiLoaded] = useState(false);
    const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    console.log('maps api key: ' + MAPS_API_KEY);
    
    const { loading: getLoading, error: getError, data: getData } = useQuery(GET_PROPERTIES)
    const [deleteProperty, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(DELETE_PROPERTY)

    const toggleModal = (property) => {
        console.log('modal togled')
        console.log(property)
        setEditModal(!editModal)
    }

    const toggleDeleteModal = (property) => {
        setDeleteModal(!deleteModal)
        setProperty(property)
    }
/*
    const onCompleted = ({ deleteProperty }) => {
        console.log(deleteProperty)
    }
    const onError = (error) => {
        console.log(error)
    }
    const update = (proxy, { data: { deleteProperty } }) => {
        try {
            if (deleteProperty) {
                const data = proxy.readQuery({ query: GET_PROPERTIES });
                data.properties = data.properties.filter(property => property._id !== deleteProperty);
                proxy.writeQuery({ query: GET_PROPERTIES, data });
            }
        } catch (error) {
            console.error(error);
        }
    }
*/
    const columns = [
        {
            title: 'Imágen',
            width: 40,
            dataIndex: 'images',
            key: '  ',
            render: (text, record, index) => (text &&

                <img alt="..."
                    className=" picture-circle icon-image-upload"
                    src={text[0]} />
            )
        },
        {
            title: 'Título',
            //width: 50,
            dataIndex: 'title',
            key: 'title',
            //fixed: 'left',
        },
        {
            title: 'Activo?',
            width: 50,
            dataIndex: 'is_enabled',
            key: 'is_enabled',
            render: (text) => (text == 0 ? <PauseCircleOutlined style={{ fontSize: 20, color: "red" }} /> : <CheckCircleOutlined style={{ fontSize: 20, color: "green" }} />)
            //fixed: 'left',
        },
        {
            title: 'Acciones',
            key: 'operation',
            //fixed: 'right',
            width: 50,
            render: (_, record) => <LineActions record={record} />
        },
    ]

    const LineActions = (props) => (
        <Dropdown
            menu={{
                items: [{
                    key: '1',
                    label: (
                        <a href="#zoom" onClick={(e) => {
                            e.preventDefault()
                            console.log('togle record')
                            console.log(props.record)
                            toggleModal(props.record)
                            setProperty(props.record)

                        }}>
                            Editar
                        </a>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <a href="#zoom" onClick={(e) => {
                            e.preventDefault()
                            toggleDeleteModal(props.record)
                        }}>Eliminar</a>
                    )
                },]
            }}
        >
            <a href="#zoom" onClick={(e) => e.preventDefault()}>
                <Space>
                    <EllipsisOutlined style={{ fontSize: '22px' }} rotate={90} />
                </Space>
            </a>
        </Dropdown>
    )

    //if (getLoading) return 'Cargando...';

    return (
        <LoadScript googleMapsApiKey={MAPS_API_KEY}>
            <Layout>
                <PropertyComponent />

                <Card className="shadow" title="Propiedades" style={{ marginTop: '40px' }}>
                    {getLoading && <Spin />}
                    {getError && `Error! ${getError.message}`}
                    {!getData || !getData.properties || getData.properties.length === 0 ? (getLoading ? null : <tr><td>Sin registros</td></tr>) :
                        <Table
                            columns={columns}
                            dataSource={getData.properties} />
                    }
                </Card>

                    <Modal
                        title="Editar Propiedad"
                        centered
                        visible={editModal}
                        onCancel={() => setEditModal(false)}
                        width={1200}
                        footer={[
                            <Button key="cancel" onClick={() => setEditModal(false)}>
                                Cancelar
                            </Button>
                        ]}
                    >
                        <PropertyComponent property={property} key={property ? property._id : 'new'} />
                    </Modal>

                    <Modal
                        title="Eliminar Propiedad"
                        centered
                        visible={deleteModal}
                        onOk={() => deleteProperty({ variables: { id: property._id } })} //setDeleteModal(false)}
                        onCancel={() => setDeleteModal(false)}
                        width={1200}>

                        <p>Confirma si deseas eliminar la Propiedad. Esta también se eliminará de las Propiedades Asignadas.</p>

                        {mutationLoading && 'Enviando...'}
                        {mutationError && `error! ${mutationError.message}`}

                    </Modal>

                
            </Layout>
        </LoadScript >
    )
}
