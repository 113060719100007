import React, { useState, useEffect } from 'react';
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { uploadImageToCloudinary, imageToBase64, filterImage, selectImage } from '../../utils/image'

const MAX_IMAGES = 10;

const ImageUploader = ({ onChange, preloadedImages = [] }) => {
    const [fileList, setFileList] = useState([]);
    const [fileListBase64, setFileListBase64] = useState(null);
    //const [uploading, setUploading] = useState(false);

    // 
    useEffect(() => {
        const initialFileList = preloadedImages.map((url, index) => ({
            uid: -index, // Es importante que uid sea único y negativo para imágenes pre-cargadas
            name: `image-${index}`,
            status: 'done',
            url: url
        }));

        setFileList(initialFileList);
    }, [preloadedImages]);

    console.log('preloadedImages ', preloadedImages);

    const handleUploadChange = ({ fileList }) => {
        if (fileList.length > MAX_IMAGES) {
            message.warning(`Solo puedes subir hasta ${MAX_IMAGES} imágenes.`);
            return;
        }
        setFileList(fileList);
        // Convertir las imágenes a base64
        const promises = fileList.map(file => imageToBase64(file.originFileObj));
        Promise.all(promises)
            .then(base64List => {
                //setFileListBase64(base64List);
                console.log('list base 64')
                console.log(base64List)

                if (onChange) {
                    onChange(base64List);
                }
            })
            .catch(error => {
                console.error(error);
            });

        
    }
    return (
        <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={handleUploadChange}
            //beforeUpload={handleBeforeUpload}
            accept=".jpg,.png"
            onRemove={ (file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
            }}
        >
            {fileList.length < MAX_IMAGES && (
                <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Subir</div>
                </div>
            )}
        </Upload>
    );
};

export default ImageUploader;
