import React, { useState, useEffect } from 'react';
import {
  Card,
  Alert,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
} from 'antd';
import Space from '../Space/Space';

import { gql, useMutation } from '@apollo/client';

import { createPlan, getPlans, editPlan } from '../../apollo/server';
import { validateFunc } from '../../constraints/constraints';

const GET_PLANS = gql`${getPlans}`;
const CREATE_PLAN = gql`${createPlan}`;
const EDIT_PLAN = gql`${editPlan}`;

const { Option } = Select;

export default function Plan(props) {
  const [plan, setPlan] = useState({
    _id: '',
    name: '',
    price: 0,
    interval: 'month',
    description: '',
    images_qty: 1,
    properties_qty: 1,
    stripePlanId: '',
    nameError: false,
    descriptionError: false,
    priceError: false,
    imageError: false,
  });

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (props.plan) {
      setPlan({
        ...plan,
        ...props.plan,
      });
    }
  }, [props.plan]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess('');
      setError('');
    }, 5000);

    return () => clearTimeout(timer);
  }, [success, error]);

  const onChange = (event, field) => {
    const value = field === 'price' || field === 'images_qty' || field === 'properties_qty' ? parseInt(event.target.value) : event.target.value;
    setPlan({
      ...plan,
      [field]: value,
    });
  };

  const onSelectChange = (value, field) => {
    setPlan({
      ...plan,
      [field]: value,
    });
  };

  const validate = () => {
    const { name, price } = plan;
    const nameError = !!validateFunc({ plan_name: name }, 'plan_name');
    const priceError = !!validateFunc({ plan_price: price }, 'plan_price');
    setPlan({
      ...plan,
      nameError,
      priceError,
    });
    if (!nameError && !priceError) return true;
    return false;
  };

  const onCompleted = ({ createPlan, editPlan }) => {
    if (createPlan) {
      setPlan({
        _id: '',
        name: '',
        price: 0,
        interval: 'month',
        description: '',
        images_qty: 1,
        properties_qty: 1,
        stripePlanId: '',
        nameError: false,
        descriptionError: false,
        priceError: false,
        imageError: false,
      });
      setSuccess('Guardado');
      setError('');
    }
    if (editPlan) {
      setSuccess('Guardado');
      setError('');
    }
  };

  const onError = (error) => {
    setSuccess('');
    setError('Un error ocurrió en el guardado, intenta nuevamente.');
  };

  const update = (proxy, { data: { createPlan } }) => {
    try {
      if (createPlan) {
        const data = proxy.readQuery({ query: GET_PLANS });
        data.plans = data.plans.concat(createPlan);
        proxy.writeQuery({ query: GET_PLANS, data });
        if (props.updatePlanList) props.updatePlanList(createPlan.map(({ _id }) => _id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDismiss = () => {
    setSuccess('');
    setError('');
  };

  const [savePlan, { loading }] = useMutation(props.plan ? EDIT_PLAN : CREATE_PLAN, {
    onCompleted,
    onError,
    update,
    refetchQueries: [{ query: GET_PLANS }],
  });

  return (
    <Card title={props.plan ? null : 'Crear Plan'}>
      <Form>
        <Input.Group size="large">
          <Row gutter={24}>
            <Col span={8}>
              <label className="form-control-label" htmlFor="input-name">
                Nombre
              </label>
              <Input
                id="input-name"
                placeholder="e.g Acceso a todas las funciones del sistema"
                type="text"
                value={plan.name}
                onChange={(event) => {
                  onChange(event, 'name');
                }}
                onBlur={() => {
                  setPlan({ ...plan, nameError: !!validateFunc({ planName: plan.name }, 'planName') });
                }}
              />
              {plan.nameError && <p className="text-red">{validateFunc({ planName: plan.name }, 'planName')}</p>}
            </Col>

            <Col span={8}>
              <label className="form-control-label" htmlFor="input-price">
                Precio
              </label>
              <Input
                id="input-price"
                placeholder="e.g 5000"
                type="number"
                value={plan.price}
                onChange={(event) => {
                  onChange(event, 'price');
                }}
                onBlur={() => {
                  setPlan({ ...plan, priceError: !!validateFunc({ planPrice: plan.price }, 'planPrice') });
                }}
              />
              {plan.priceError && <p className="text-red">{validateFunc({ planPrice: plan.price }, 'planPrice')}</p>}
            </Col>

            <Col span={8}>
              <label className="form-control-label" htmlFor="input-interval">
                Intervalo
              </label>
              <Select
                id="input-interval"
                value={plan.interval}
                onChange={(value) => {
                  onSelectChange(value, 'interval');
                }}
              >
                <Option value="month">Mes</Option>
                <Option value="year">Año</Option>
              </Select>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <label className="form-control-label" htmlFor="input-description">
                Descripción
              </label>
              <Input
                id="input-description"
                placeholder="e.g Acceso a todas las funciones del sistema"
                type="text"
                value={plan.description}
                onChange={(event) => {
                  onChange(event, 'description');
                }}
                onBlur={() => {
                  setPlan({
                    ...plan,
                    descriptionError: !!validateFunc({ planDescription: plan.description }, 'planDescription'),
                  });
                }}
              />
              {plan.descriptionError && <p className="text-red">{validateFunc({ planDescription: plan.description }, 'planDescription')}</p>}
            </Col>

            <Col span={8}>
              <label className="form-control-label" htmlFor="input-images_qty">
                Cantidad Máxima de Imágenes
              </label>
              <Input
                id="input-images_qty"
                placeholder="e.g 1000"
                type="number"
                value={plan.images_qty}
                onChange={(event) => {
                  onChange(event, 'images_qty');
                }}
                onBlur={() => {
                  setPlan({ ...plan, images_qtyError: !!validateFunc({ planName: plan.images_qty }, 'planName') });
                }}
              />
              {plan.images_qtyError && <p className="text-red">{validateFunc({ planName: plan.images_qty }, 'planName')}</p>}
            </Col>

            <Col span={8}>
              <label className="form-control-label" htmlFor="input-properties_qty">
                Cantidad Máxima de Propiedades
              </label>
              <Input
                id="input-properties_qty"
                placeholder="e.g 10"
                type="number"
                value={plan.properties_qty}
                onChange={(event) => {
                  onChange(event, 'properties_qty');
                }}
                onBlur={() => {
                  setPlan({
                    ...plan,
                    properties_qtyError: !!validateFunc({ planPrice: plan.properties_qty }, 'planPrice'),
                  });
                }}
              />
              {plan.properties_qtyError && <p className="text-red">{validateFunc({ planPrice: plan.properties_qty }, 'planPrice')}</p>}
            </Col>
          </Row>

          {props.plan && (
            <Row gutter={24}>
              <Col span={24}>
                <Space />
                {plan.stripePlanId ? (
                  <Alert
                    message="Detalles del plan de Stripe"
                    description={
                      <div>
                        <p>Stripe Plan ID: {plan.stripePlanId}</p>
                      </div>
                    }
                    type="info"
                    showIcon
                  />
                ) : (
                  <Alert
                    message="No has generado un plan de Stripe"
                    type="warning"
                    showIcon
                  />
                )}
                <Space />
              </Col>
            </Row>
          )}

          <Row>
            <Col gutter={24}>
              <Button
                type="primary"
                size="lg"
                block
                disabled={loading}
                onClick={async () => {
                  if (validate()) {
                    await savePlan({
                      variables: {
                        planInput: {
                          _id: props.plan ? props.plan._id : '',
                          name: plan.name,
                          price: plan.price,
                          interval: plan.interval,
                          description: plan.description,
                          images_qty: plan.images_qty,
                          properties_qty: plan.properties_qty,
                          stripePlanId: plan.stripePlanId,
                        },
                      },
                    });
                  }
                }}
              >
                Guardar
              </Button>
            </Col>
          </Row>

          {success && (
            <Row style={{ marginTop: '20px' }}>
              <Col span={24}>
                <Alert
                  message={success}
                  type="success"
                  showIcon
                  closable
                  onClose={onDismiss}
                />
              </Col>
            </Row>
          )}

          {error && (
            <Row style={{ marginTop: '20px' }}>
              <Col span={24}>
                <Alert
                  message={error}
                  type="error"
                  showIcon
                  closable
                  onClose={onDismiss}
                />
              </Col>
            </Row>
          )}
        </Input.Group>
      </Form>
    </Card>
  );
}
