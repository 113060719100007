import React, { useState } from "react";
import { Select, Spin } from "antd";
import { useLazyQuery } from "@apollo/client";
import { gql } from '@apollo/client';

const { Option } = Select;

const ZoomSelect = ({ graphqlQuery, onSelectCallback, defaultValue, defaultLabel, dataKey, idKey = "_id", nameLabel = "name" }) => {
    //  const [options, setOptions] = useState([]);
    //const [options, setOptions] = useState([{ value: defaultValue, label: defaultLabel }]);
    const initialOptions = defaultValue && defaultLabel ? [{ value: defaultValue, label: defaultLabel }] : [];
    const [options, setOptions] = useState(initialOptions);
    const [loading, setLoading] = useState(false);

    const [getOptions, { loading: fetchLoading }] = useLazyQuery(graphqlQuery, {
        onCompleted: (data) => {
            console.log("users fetched");
            console.log(data);
            const fetchedOptions = data[dataKey].map((option) => ({
                value: option[idKey],
                label: option[nameLabel],
                data: option,
            }));
            setOptions(fetchedOptions);
            setLoading(false);
        },
    });

    const handleSearch = (value) => {
        setLoading(true);
        if (value.length > 1) {
            getOptions({ variables: { searchValue: value } });
        } else {
            setOptions([]);
            setLoading(false);
        }
    };

    const handleChange = (value, option) => {
        onSelectCallback(option.data);
    };

    // Intentar extraer el nombre de la propiedad de los datos de la consulta GraphQL
    if (!dataKey) {
        /*const queryDef = gql(graphqlQuery).definitions[0];
        const fragmentDef = queryDef.kind === "OperationDefinition" ? queryDef : queryDef.selectionSet.selections[0];
        console.log('framend def')
        console.log(fragmentDef)*/
        //const { name } = getQueryDefinition(graphqlQuery);
        //dataKey = name;
        //datakey = 'options';
    }

    return (
        <Select
            showSearch
            placeholder="Selecciona una opción"
            optionFilterProp="children"
            defaultValue={defaultValue}
            filterOption={false}
            onSearch={handleSearch}
            onSelect={handleChange}
            loading={loading || fetchLoading}
            notFoundContent={loading ? <Spin size="small" /> : null}
        >
            {options.map((option) => (
                <Option key={option.value} value={option.value} data={option.data}>
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default ZoomSelect;
