import React, { useState } from 'react';
import {
    Container,
    Row,
} from 'reactstrap';
import { Table, Card as CardAnt, Modal as ModalAnt, Dropdown, Space, Button, Spin, Tag } from 'antd'
import { EllipsisOutlined, DownOutlined, SmileOutlined, CheckCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'
//import Header from "../components/Headers/Header.jsx";
import PlanComponent from '../components/Plan/Plan';
import { gql, useMutation, useQuery } from '@apollo/client';
//import { transformToNewline } from '../utils/stringManipulations'
import { getPlans, deletePlan } from '../apollo/server';

const GET_PLANS = gql`${getPlans}`;
const DELETE_PLAN = gql`${deletePlan}`;

export default function Plan(props) {

    const [plan, setPlan] = useState(null)
    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    const { loading: getLoading, error: getError, data: getData } = useQuery(GET_PLANS)
    const [deletePlanMutation, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(DELETE_PLAN)

    const toggleModal = (plan) => {
        console.log('modal togled')
        console.log(plan)
        setEditModal(!editModal)
    }

    const toggleDeleteModal = (plan) => {
        setDeleteModal(!deleteModal)
        setPlan(plan)
    }

    const onCompleted = ({ deletePlan }) => {
        console.log(deletePlan)
    }
    const onError = (error) => {
        console.log(error)
    }
    const update = (proxy, { data: { deletePlan } }) => {
        try {
            if (deletePlan) {
                const data = proxy.readQuery({ query: GET_PLANS });
                data.plans = data.plans.filter(plan => plan._id !== deletePlan);
                proxy.writeQuery({ query: GET_PLANS, data });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const columns = [
        {
            title: 'Nombre',
            //width: 50,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Stripe ID',
            width: 50,
            dataIndex: 'stripePlanId',
            key: 'stripePlanId',
            render: (text) => {
                return `${text}`
            }
        },
        {
            title: 'Intervalo',
            width: 50,
            dataIndex: 'interval',
            key: 'interval',
            render: (text) => {
                return `${text}`
            }
        },
        {
            title: 'Activo?',
            width: 50,
            dataIndex: 'is_enabled',
            key: 'is_enabled',
            render: (text) => (text === 0 ? <PauseCircleOutlined style={{ fontSize: 20, color: "red" }} /> : <CheckCircleOutlined style={{ fontSize: 20, color: "green" }} />)
            //fixed: 'left',
        },
        {
            title: 'Acciones',
            key: 'operation',
            //fixed: 'right',
            width: 50,
            render: (_, record) => <LineActions record={record} />
        },
    ]

    const LineActions = (props) => {
        return (
            <Dropdown
                menu={{
                    items: [{
                        key: '1',
                        label: (
                            <a href="#zoom" onClick={(e) => {
                                e.preventDefault()
                                console.log('togle record')
                                console.log(props.record)
                                toggleModal(props.record)
                                setPlan(props.record)
                            }}>
                                Editar
                            </a>
                        ),
                    },
                    {
                        key: '2',
                        label: (
                            <a href="#zoom" onClick={(e) => {
                                e.preventDefault()
                                toggleDeleteModal(props.record)
                            }}>Eliminar</a>
                        )
                    },]
                }}
            >
                <a href="#zoom" onClick={(e) => e.preventDefault()}>
                    <Space>
                        <EllipsisOutlined style={{ fontSize: '22px' }} rotate={90} />
                    </Space>
                </a>
            </Dropdown>
        );
    }

    return (
        <>
            {/* Page content */}
            <Container className="mt--7" fluid>

                <PlanComponent />

                <Row className="mt-5">
                    <div className="col">

                        <CardAnt className="shadow" title="Planes">

                            {getLoading && <Spin />}
                            {getError && `Error! ${getError.message}`}
                            {!getData || !getData.plans || getData.plans.length === 0 ? (getLoading ? null : <tr><td>Sin registros</td></tr>) :
                                <Table
                                    columns={columns}
                                    dataSource={getData.plans}
                                />
                            }

                        </CardAnt>
                    </div>
                </Row>

                <ModalAnt
                    title="Editar Plan"
                    centered
                    open={editModal}
                    onCancel={() => setEditModal(false)}
                    width={800}
                    footer={[
                        <Button key="cancel" onClick={() => setEditModal(false)}>
                            Cancelar
                        </Button>
                    ]}
                >
                    <PlanComponent plan={plan} />
                </ModalAnt>

                <ModalAnt
                    title="Eliminar Plan"
                    centered
                    open={deleteModal}
                    onOk={() => deletePlanMutation({ variables: { id: plan._id }, update })}
                    onCancel={() => setDeleteModal(false)}
                    width={800}
                >
                    <p>Confirma si deseas eliminar el Plan. Esta acción no puede ser revertida.</p>

                    {mutationLoading && 'Enviando...'}
                    {mutationError && `error! ${mutationError.message}`}
                </ModalAnt>

            </Container>
        </>
    )
}