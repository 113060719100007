import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { Input, Button } from "antd";

const MapSearch = ({ onLocationSelected, defaultLocation }) => {

    const initialCenter = defaultLocation
        ? { lat: parseFloat(defaultLocation.lat), lng: parseFloat(defaultLocation.lng) }
        : { lat: 9.4337383, lng: -99.1454316 };

    const [center, setCenter] = useState(initialCenter);
    const [markerPosition, setMarkerPosition] = useState(initialCenter);
    const [searchQuery, setSearchQuery] = useState(defaultLocation ? defaultLocation.address : "");
    const [isMarkerShown, setIsMarkerShown] = useState(!!defaultLocation);

    /*const [center, setCenter] = useState({ lat: 9.4337383, lng: -99.1454316 });
    const [markerPosition, setMarkerPosition] = useState(center);
    const [searchQuery, setSearchQuery] = useState("");
    const [isMarkerShown, setIsMarkerShown] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);*/


    // Actualiza los estados cuando cambian las props
    /*useEffect(() => {
        console.log('map loaded ', mapLoaded);
        if (defaultLocation && mapLoaded) {
            console.log('map loaded ',  mapLoaded);
            console.log('map loaded ', defaultLocation);
            setCenter({ lat: parseFloat(defaultLocation.lat), lng: parseFloat(defaultLocation.lng) });
            setMarkerPosition({ lat: parseFloat(defaultLocation.lat), lng: parseFloat(defaultLocation.lng) });
            setSearchQuery(defaultLocation.address);
            setIsMarkerShown(true);
            console.log('default location', parseFloat(defaultLocation.lng));
        }
    }, [defaultLocation, mapLoaded]);*/

    const handleSearch = useCallback(
        (value) => {
            setSearchQuery(value);
            setIsMarkerShown(false);

            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address: value }, (results, status) => {
                if (status === "OK") {
                    const location = results[0].geometry.location;
                    setCenter({ lat: location.lat(), lng: location.lng() });
                    setMarkerPosition({ lat: location.lat(), lng: location.lng() });
                    setIsMarkerShown(true);
                    if (onLocationSelected) {
                        console.log('on selected')
                        console.log(value)
                        onLocationSelected({ 
                            address:value,
                            lat: location.lat(), 
                            lng: location.lng() 
                        })
                    }
                } else {    
                    console.log("Geocode was not successful for the following reason: " + status);
                }
            });
        },
        [onLocationSelected]
    );

    /*const handleMapClick = (event) => {
        setMarkerPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    };*/

    const handleMapClick = async (event) => {
        console.log(event.latLng.lat(), event.latLng.lng());
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: event.latLng }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    setSearchQuery(results[0].formatted_address);
                    setMarkerPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
                    /*setMarker({
                        position: { lat: event.latLng.lat(), lng: event.latLng.lng() },
                        label: { color: "white", text: "P111" },
                        draggable: true
                    });*/
                    //onLocationChange({ lat: event.latLng.lat(), lng: event.latLng.lng() });
                    if (onLocationSelected) {
                        onLocationSelected({
                            address: results[0].formatted_address,
                            lat: event.latLng.lat(),
                            lng: event.latLng.lng(),
                        })
                    }
                } else {
                    console.log("No se encontró la dirección para las coordenadas seleccionadas.");
                }
            } else {
                console.log("No se pudo obtener la dirección para las coordenadas seleccionadas.");
            }
        });
    };

   /* const handleMapLoad = (map) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setCenter({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                map.panTo({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            },
            () => null,
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            }
        );

        //setMapLoaded(true);
    };*/

    const handleMapLoad = useCallback((map) => {
        if (defaultLocation) {
            map.panTo(initialCenter);
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const newCenter = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setCenter(newCenter);
                    map.panTo(newCenter);
                },
                () => null,
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                }
            );
        }
    }, [defaultLocation]);


    const handleMarkerClick = () => {
        setIsMarkerShown(!isMarkerShown);
    };

    /*const handleResetMarker = () => {
        setMarkerPosition(center);
        setIsMarkerShown(true);
         if (onLocationSelected) {
            onLocationSelected(center);
         }
    };*/

    const containerStyle = {
        width: "100%",
        height: "400px",
    };

    return (
        <>

            <Input.Search
                placeholder="Escribe la dirección de tu propiedad"
                enterButton="Buscar"
                size="large"
                onSearch={handleSearch}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom:16 }}
            />

            <GoogleMap 
                mapContainerStyle={containerStyle} 
                center={center} 
                zoom={15}
                onClick={handleMapClick}
                onLoad={handleMapLoad}
            >

                {isMarkerShown  && (
                    <MarkerF 
                    position={markerPosition} 
                    onClick={handleMarkerClick} 
                    draggable 
                    onDragEnd={(event) => {
                        const lat = event.latLng.lat();
                        const lng = event.latLng.lng();
                        setMarkerPosition({ lat, lng });
                        onLocationSelected({
                            address: searchQuery,
                            lat: lat,
                            lng: lng,
                        })
                    }}/>
                    
                )}
            </GoogleMap>
            
            {/*isMarkerShown && (
                <Button type="primary" onClick={handleResetMarker} style={{ marginTop: 16 }}>
                    Restablee
                </Button>
            )*/}
        </>
    );
};

export default MapSearch;
