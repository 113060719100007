import React from 'react';

const Space = ({ children, height = 15, style }) => {
    const containerStyle = {
        height,
        ...style,
    };

    return (
        <div style={containerStyle}>
            {children}
        </div>
    );
};

export default Space;
