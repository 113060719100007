import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";

class PublicNavbar extends React.Component {

  render() {
    return (
      <>
        <Navbar
          className="navbar-top navbar-horizontal navbar-dark"
          expand="md"
        >
          <Container className="px-4">
            <div className="d-flex flex-row justify-content-between align-items-center w-100"> {/* Add this wrapper */}
              <Link to="/">
                <img
                  alt="..."
                  src={require("../../assets/img/brand/logo.png")}
                  style={{ height: 150 }}
                />
              </Link>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink className="nav-link-icon" to="/" tag={Link}>
                    <span className="nav-link-inner--text">Home</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link-icon" to="/privacidad" tag={Link}>
                    <span className="nav-link-inner--text">Privacidad</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link-icon" to="/cancelaciones" tag={Link}>
                    <span className="nav-link-inner--text">Cancelaciones</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div> {/* Close the wrapper */}
          </Container>
        </Navbar>
      </>
    );
  }
}

export default PublicNavbar;
