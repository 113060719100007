import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../components/Auth/AuthProvider.jsx';

export const ProtectedRoute =  ({ children }) => {
    const { token, checkLogin } = useAuth();
    const location = useLocation();
    console.log('login is ' + checkLogin())
    console.log(location)
    if (checkLogin() == null) {
        return <Navigate to="/auth/login" replace state={{ from: location }} />;
    }

    return children;
};


