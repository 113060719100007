import React from "react";


class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-warning pb-8 pt-5 pt-md-8">
        </div>
      </>
    );
  }
}

export default Header;