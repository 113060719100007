import { Button, Form, Input, Popconfirm, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import SearchSelect from '../SearchSelect/SearchSelect'

//import { gql, useMutation, useQuery } from '@apollo/client';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const TableEdit = (props) => {

  const [dataSourceTable, setDataSourceTable] = useState([]);
  const [count, setCount] = useState(2);

  // Inicializa dataSourceTable con props.characteristics
  /*useEffect(() => {
    if (props.dataDefault) {
      setDataSourceTable(props.characteristics.map((item, index) => ({ ...item, key: index })));
      setCount(props.characteristics.length + 1);
    }
  }, [props.characteristics]);*/

  console.log('PROPS' ,  props);

  useEffect(() => {
    if (props.dataDefault && (!dataSourceTable || dataSourceTable.length === 0)) {
      console.log('CHARACETRISTICS ', props.dataDefault);
      const formattedData = props.dataDefault.map((item, index) => ({
        key: item._id,
        _id: item._id,  
        icon: item.characteristic.icon,
        title: item.characteristic.title,
        qty: item.qty,
      }));
      setDataSourceTable(formattedData);
      setCount(formattedData.length + 1);
    }
  }, [props.dataDefault]);

  // Callback onChange of table
  const handleOnChange = (newData) =>{
    // New data
    setDataSourceTable(newData);
    // Callback
    if(props.onChange) { 
      //console.log('props onchange')
      //console.log(newData)
      props.onChange(newData)
    }
  }

  const handleDelete = (key) => {
    const newData = dataSourceTable.filter((item) => item.key !== key);
    handleOnChange(newData);
  };
  const defaultColumns = props.columns(dataSourceTable, handleDelete) || []

  const handleAdd = (item) => {
    const newData = {
      key: count,
      qty: 1,
      ...item
    };
    handleOnChange([...dataSourceTable, newData]);
    setCount(count + 1);
  };

  const handleSave = (row) => {
    const newData = [...dataSourceTable];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    //console.log('handle save result')
    //console.log(newData)
    handleOnChange(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });


  return (
    <div>

      <SearchSelect
        query={props.search?.query}
        query_name={props.search?.query_name} // gql query name
        value={props.search.value}
        label={props.search.label}
        placeholder={props.search?.placeholder || "Elige una opción"}
        //data={props.data || []} // pair value/label
        onChange={(item) => {
          handleAdd(item)
        }}
      />

      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSourceTable}
        columns={columns}
        pagination={{
          position: ['none', 'none'],
        }}

      />
    </div>
  );
};
export default TableEdit;