
import { Link, Outlet, Navigate, useLocation } from "react-router-dom"
import { Container, Row } from "reactstrap"
// core components
import AuthNavbar from "../components/Navbars/AuthNavbar"
import AuthFooter from "../components/Footers/AuthFooter"

import { useAuth } from '../components/Auth/AuthProvider.jsx';

export default function AuthLayout() {

  // Revisa login
  const { checkLogin } = useAuth();
  const location = useLocation();
  
  if (checkLogin()) {
    return <Navigate to="/admin/dashboard" replace state={{ from: location }} />;
  }

  return (
    <>
      <div className="main-content zoom-bg-auth">

        <AuthNavbar />

        <div className="header py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
          </div>
        </div>

        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">

            <Outlet />

          </Row>
        </Container>
      </div>
      {/*<AuthFooter />*/}
    </>
  );
}