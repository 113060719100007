import React from 'react';
import {
    Container,
    Row,
    Card,
    CardHeader,
    Table,
    Media

} from "reactstrap";
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import { getUsers } from "../apollo/server";
import { transformToNewline } from '../utils/stringManipulations';

const GET_USERS = gql`${getUsers}`;

function Users() {
    console.log('users work')
    const { t } = useTranslation();

    const { loading, error, data } = useQuery(GET_USERS, { onError: error => { console.log(error) } });

    if (loading) return <div>{t("Loading")}</div>;
    if (error) return <div>`${t("Error")}! ${error.message}`</div>;

    return (
        <>
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row >
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">{t("Usuarios")}</h3>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">{t("Nombre")}</th>
                                        <th scope="col">{t("Email")}</th>
                                        <th scope="col">{t("Teléfono")}</th>
                                        <th scope="col">{t("Rol")}</th>
                                        <th scope="col">{t("Membresía")}</th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.users.map(user =>
                                        <tr key={user._id}>
                                            <th scope="row">
                                                <Media>
                                                    <span className="mb-0 text-sm">
                                                        {user.name}
                                                    </span>
                                                </Media>
                                            </th>
                                            <td>{user.email}</td>
                                            <td>{user.phone}</td>
                                            <td>{user.role}</td>
                                            <td>{user.membership}</td>
                                            {/*<td style={{ whiteSpace: 'pre' }}>{transformToNewline(user.addresses.length ? user.addresses[0].delivery_address : '', 15)}</td>*/}
                                            <td></td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default Users;
