
import { Link, Outlet, Navigate, useLocation } from "react-router-dom"
import { Container, Row } from "reactstrap"
// core components
import PublicNavbar from "../components/Navbars/PublicNavbar"


export default function PublicLayout() {

  return (
    <>
      <div className="main-content zoom-bg-public">
        <PublicNavbar />
        <div className="header py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
          </div>
        </div>
        <Container className="mt--9 pb-5">
          <Row className="justify-content-center">
            <Outlet />
          </Row>
        </Container>
      </div>
      {/*<AuthFooter />*/}
    </>
  );
}