import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  Alert,
  Popconfirm

} from 'antd';
//import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';

//import { withTranslation } from 'react-i18next';
import { Mutation } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
//import { gql, compose, withApollo } from '@apollo/client';

import { createProperty, getProperties, editProperty, getCharacteristics, getUserSearch } from '../../apollo/server';
//import OptionsComponent from '../Option/Option';
//import { validateFunc } from '../../constraints/constraints'
//import { uploadImagesToCloudinary, imageToBase64, filterImage, selectImage } from '../../utils/image'
import { uploadImagesToCloudinary } from '../../utils/image'
import { cloudinary_property } from "../../config/config";
//import { setSelectionRange } from '@testing-library/user-event/dist/utils';

import ImageUploader from '../ImageUploader/ImageUploader'
import MapSearch from '../Maps/MapSearch'
import ZoomSelect from '../SearchSelect/ZoomSelect'

import TableEdit from '../TableEdit/TableEdit'

const GET_PROPERTIES = gql`${getProperties}`
const CREATE_PROPERTY = gql`${createProperty}`
const EDIT_PROPERTY = gql`${editProperty}`
const GET_CHARACTERISTICS = gql`${getCharacteristics}`
const GET_USER_SEARCH = gql`${getUserSearch}`

export default function Property(props) {

  const [key, setKey] = useState(0);

  const initial_state = {
    title: 'Propiedad 2',
    description: 'Test 2',
    short_description: 'Test 3',
    characteristics: [],
    price: null,
    priceDesc: null,
    likes: 0,
    images: [],
    user: '',
    liked: false,
    is_active: true,
    is_enabled: false,
    is_reserved: false,
    maps_location: {
      address: '',
      lat: '',
      lne: ''
    },
  }

  const [property, setProperty] = useState(initial_state)
  const [imagesBase64Array, setImagesBase64Array] = useState([])
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const uploaderRef = useRef(null)
  //const [propertyIndex, setPropertyIndex] = useState(0)

  useEffect(() => {
    if (props.property) {
      
      console.log('PROPERTY USEEFFECT ', props.property);

      // Characteristics Prepare for update
      let characteristics = [];

      if (props.property.characteristics) {
        console.log('CHARACETRISTICS ', props.property.characteristics);
        characteristics = props.property.characteristics.map((item, index) => ({
          characteristic: item._id,
          qty: item.qty,
        }));

        console.log('CHARACETRISTICS TRABSFORMED ', characteristics);
      }
      // Maps Location transform
      const { __typename, lat, lng, ...locationWithoutTypename } = props.property.maps_location || {};
      const latFloat = lat ? parseFloat(lat) : null;
      const lngFloat = lng ? parseFloat(lng) : null;

      setProperty({
        title: props.property.title || '', 
        price: props.property.price || null,
        priceDesc: props.property.priceDesc || null,
        description: props.property.description || '',
        short_description: props.property.short_description || '',
        // TODO MAPEAR
        characteristics: characteristics || [],
        likes: props.property.likes || 0,
        images: props.property.images || [],
        user: props.property.user._id || '',
        liked: props.property.liked || false,
        is_active: props.property.is_active || true,
        is_enabled: props.property.is_enabled || false,
        is_reserved: props.property.is_reserved || false,
        maps_location: (Object.keys(locationWithoutTypename).length === 0 && latFloat === null && lngFloat === null) ? null : { ...locationWithoutTypename, lat: latFloat, lng: lngFloat },
        //createdAt: props.property.createdAt,
        //updatedAt: props.property.updatedAt,e
      })
    }
  }, [props.property]);

  useEffect(() => {
    // Cada vez que cambia la propiedad, actualizar la clave
    setKey(prev => prev + 1);
  }, [property]);

  const resetPropertyState = () => {
    setProperty(initial_state);
  };

  /*const onChange = (event, index, state) => {
    setProperty(property => ({
      ...property,
      [index]: event.target.value,
    }))
    console.log('property on chngw ')
    console.log(property)
  }*/
  const onChange = (event, field) => {
    setProperty(property => ({
      ...property,
      [field]: event.target.value,
    }));
  };

  const validate = () => {
    //const characteristics = state.characteristics
    /*characteristics.map((characteristic, index) => {
      onBlur(index, 'title')
      onBlur(index, 'description')
      onBlur(index, 'quantity_minimum')
      onBlur(index, 'quantity_maximum')
      onBlur(index, 'options')
      return characteristic
    })
    const error = characteristics.filter(characteristic => characteristic.titleError || characteristic.descriptionError || characteristic.quantity_minimumError || characteristic.quantity_maximumError || characteristic.optionsError)
    if (!error.length)
      return true
    return false*/
    return true
  }
  const onCompleted = ({ createProperty, editProperty }) => {
    if (createProperty) {
      setProperty(initial_state)
      setSuccess('Guardado')
      setError('')
    }

    if (editProperty) {
      setSuccess('Guardado')
      setError('')
    }
  }
  const onError = (error) => {
    setSuccess('')
    setError('Un error ocurrió en el guardado, intenta nuevamente. ')
    //setState({ error: 'An error occured while saving,Try again', success: '' })
  }

  // This is a function that is called when a mutation is called
  const update = (proxy, { data: { createProperties } }) => {
    // Try to run the following code
    try {
      // If the mutation is successful
      if (createProperties) {
        // Read the query that is being used
        const data = proxy.readQuery({ query: GET_PROPERTIES });
        // Add the new data to the query
        data.properties = data.properties.concat(createProperties);
        // Write the new data to the query
        proxy.writeQuery({ query: GET_PROPERTIES, data });

        if (props.updatepropertiesList)
          props.updatepropertiesList(createProperties.map(({ _id }) => _id))
      }
    } catch (error) {
      // If there is an error, log it to the console
      console.error(error);
    }
  }

  const onDismiss = () => {
    setSuccess('')
    setError('')
  }


  const getUrls = async () => {
    let resultUrls = [];

    // Subir nuevas imágenes a Cloudinary y obtener sus URLs
    if (imagesBase64Array.length > 0) {
      const uploadedImagesUrls = await uploadImagesToCloudinary(
        imagesBase64Array,
        cloudinary_property,
        () => imagesBase64Array.length > 0
      );

      console.log('Resultado de Cloudinary', uploadedImagesUrls);

      // Asegúrate de que uploadedImagesUrls es un arreglo
      if (Array.isArray(uploadedImagesUrls) && uploadedImagesUrls.length > 0) {
        resultUrls = [...uploadedImagesUrls];
      }
    }

    // Combinar con imágenes existentes si están presentes
    if (props.property && Array.isArray(props.property.images)) {
      resultUrls = [...resultUrls, ...props.property.images];
    }

    return resultUrls;
  }


  const { TextArea } = Input;

  return (
    <Card 
      title={props.property ? null : "Crear Propiedad"}
      actions={[
        (<Mutation mutation={props.property ? EDIT_PROPERTY : CREATE_PROPERTY}
          onCompleted={onCompleted}
          onError={onError}
          update={update}
          refetchQueries={[{ query: GET_PROPERTIES }]}>

          {(mutate, { loading }) => {
            
            if (loading) return (<Button disabled color="success" size="lg" block>Guardando</Button>)
            
            return (< Button
              type="primary" size="lg"
              block
              onClick={async () => {
                
                if (!validate()) {
                  console.log('Error al validar los datos');
                  return;
                }

                const input = {
                  ...property,
                  price: parseInt(property.price, 10), // Actualizar el precio
                  priceDesc: parseInt(property.priceDesc, 10), // Actualizar el precioDesc
                  _id: props.property ? props.property._id : '',
                  images: await getUrls(),
                };

                //await uploaderRef.current.handleUpload();
                console.log(' INPUT PROPERTY', input);
                //return;
                
                /*mutate({
                    variables: {
                      propertyInput: input
                    }
                });*/

                mutate({
                  variables: {
                    propertyInput: input,
                  },
                  onCompleted: () => {
                    if (!props.property) {
                      // Si props.property es null o undefined, resetea el estado
                      resetPropertyState();
                    }
                  },
                });

              }}> Guardar</Button>)
          }}
        </Mutation>),

      ]}
    >
      <Form>
        <Input.Group size="large">
          <Row gutter={16}>
            <Col span={8} >

              <label className="form-control-label" htmlFor="input-title">
                Título
              </label>

              <Input
                //className="form-control-alternative"
                id="input-title"
                placeholder="e.g "
                type="text"
                value={property.title}
                onChange={(event) => {
                  onChange(event, 'title')
                }}
              //onBlur={event => { onBlur('title') }} 
              />

            </Col>
            <Col span={6} >

                <div>
              <label className="form-control-label" htmlFor="input-user">
                Vendedor
              </label>
              </div>
              
              <ZoomSelect 
                graphqlQuery= {GET_USER_SEARCH}
                dataKey= 'userSearch'
                defaultValue={props.property?.user?._id}
                defaultLabel={props.property?.user?.name}
                onSelectCallback = {
                  (data)=>{
                    console.log(data)
                    setProperty(property => ({
                      ...property,
                      user: data._id // User es el id
                    }))
                  }
                }
              />
             
              {/*
              <Input
                //className="form-control-alternative"
                id="input-user"
                placeholder="e.g "
                type="text"
                value={property.user}
                onChange={(event) => {
                  //onChange(event, 'user')
                  setProperty({
                    ...property,
                    user: event.target.value
                    /*user: {
                      _id  : event.target.value,
                      name: ''
                    }
                  })
                }}
              onBlur={event => { onBlur('description') }} 
              />*/}
            </Col>
            <Col span={2} >

              <label className="form-control-label" htmlFor="input-title">
                Activo
              </label>

              <div>
                <Switch checked={property.is_enabled} onChange={(checked) => {
                  console.log(`switch to ${checked}`)
                  setProperty(property => ({
                    ...property,
                    is_enabled: checked
                  }))
                }} />
              </div>

            </Col>
            <Col span={2} >

              <label className="form-control-label" htmlFor="input-is_reserved">
                Reservado
              </label>

              <div>
                <Switch checked={property.is_reserved} onChange={(checked) => {
                  console.log(`switch to ${checked}`)
                  setProperty(property => ({
                    ...property,
                    is_reserved: checked ? true : false
                  }))
                }} />
              </div>
            </Col>
          </Row>
          <Row gutter={12}>

            <Col span={6} >

              <label className="form-control-label" htmlFor="input-title">
                Precio
              </label>

              <CurrencyInput
                //className="form-control-alternative"
                id="input-price"
                placeholder="e.g. $100,000"
                //defaultValue={property.price}
                prefix="$"
                groupSeparator=","
                decimalSeparator="."
                value={property.price}
                /*onChange={(event) => {
                  onChange(event, 'price')
                }}*/
                onValueChange={(value, name) => onChange({ target: { name, value } }, 'price')}
              //onBlur={event => { onBlur('title') }} 
                className="antd-input-style"
              />

            </Col>
            <Col span={6} >

              <label className="form-control-label" htmlFor="input-user">
                Precio descuento
              </label>

              <CurrencyInput
                //className="form-control-alternative"
                id="input-priceDesc"
                placeholder="e.g. $100,000"
                value={property.priceDesc}
                prefix="$"
                groupSeparator=","
                decimalSeparator="."
                //value={property.priceDesc}
                /*onChange={(event) => {
                  onChange(event, 'price')
                }}*/
                onValueChange={(value, name) => onChange({ target: { name, value } }, 'price')}
                //onBlur={event => { onBlur('title') }} 
                className="antd-input-style"
              />

            </Col>
         </Row>
          <Row gutter={12}>            
            <Col span={12}>
              <label className="form-control-label" htmlFor="input-title">
                Descripción Corta
              </label>

              <TextArea
                //className="form-control-alternative"
                id="input-short_description"
                type="textarea"
                value={property.short_description}
                onChange={(event) => {
                  onChange(event, 'short_description')

                }}
                style={{ height: 200 }}
              //onBlur={event => { onBlur('short_description') }} 
              />

              <label className="form-control-label" htmlFor="input-title">
                Descripción
              </label>

              <TextArea
                //className="form-control-alternative"
                id="input-description"
                placeholder="e.g "
                type="textarea"
                value={property.description}
                onChange={(event) => {
                  onChange(event, 'description')

                }}
                style={{ height: 300 }}
              //onBlur={event => { onBlur('description') }} 
              />

            </Col>
            <Col span={12}>

              <label className="form-control-label" htmlFor="input-maps_location">
                Ubicación
              </label>

              <MapSearch 
                defaultLocation = {props.property?.maps_location}
                onLocationSelected={async (location) => {
                  await setProperty(property => ({
                    ...property,
                    maps_location: location
                  }));
                }} 
                //key={key}
              />


            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <label className="form-control-label" htmlFor="input-title">
                Características
              </label>

              <TableEdit
                //value={property.characteristics}
                //query={GET_PROPERTYCHARACTERISTICS}
                //dataDefault={property.characteristics} // Data
                dataDefault={props.property?.characteristics || []} // Data
                onChange={async (dataList) => {

                  console.log(' datalist -----', dataList);
                  
                  if (dataList) {
                    let list_selected = []
                    for (let obj of dataList) {
                      //delete obj['__typename'];
                      list_selected.push({characteristic: obj._id, qty: obj.qty});
                    }
                    console.log('characteristics datalist -----')
                    console.log(list_selected)
                    // Characteristic
                    console.log()
                    await setProperty(property => ({
                      ...property,
                      characteristics: list_selected
                    }));
                    console.log('characteristics selected')
                    console.log(property)
                    //onChange(event, 'description')
                  }
                }}
                search={{
                  'placeholder': 'Selecciona una característica',
                  'button': null,
                  'query': GET_CHARACTERISTICS,
                  'query_name': 'characteristics',
                  'value': '_id',
                  'label': 'title'
                }}
                columns={(dataSource, handleDelete) => [
                  {
                    title: 'Icono',
                    dataIndex: 'icon',
                    width: '10%',
                    editable: false,
                    render: (text, record, index) => (text && typeof text === 'string' &&
                      <img alt="..."
                        className=" picture-circle icon-image-upload"
                        src={text} />
                    )
                  },
                  {
                    title: 'Nombre',
                    dataIndex: 'title',
                    width: '70%',
                    editable: false,
                  },
                  {
                    title: 'Cantidad',
                    dataIndex: 'qty',
                    width: '10%',
                    editable: true,
                  },
                  {
                    title: 'Acción',
                    dataIndex: 'accion',
                    width: '10%',
                    render: (_, record) =>
                      dataSource.length >= 1 ? (
                        <Popconfirm
                          title="Seguro que quieres quitar este renglón?"
                          onConfirm={() => handleDelete(record.key)}>
                          <a href='#zoom' onClick={(e) => e.preventDefault()}>Quitar</a>
                        </Popconfirm>
                      ) : null,
                  },
                ]}
              />

            </Col>

            <Col span={12} >

              <label className="form-control-label" htmlFor="input-title">
                Imágenes
              </label>
              <div >

                <ImageUploader 
                  ref={uploaderRef} 
                  preloadedImages={props.property?.images || []}
                  onChange={(base64List) => { // Callback para modificar el estado de la imagen del iconos

                      console.log('fileReader desde onchange en input')
                      console.log(base64List)
                      // Base 64 preloaded images, before submit
                    setImagesBase64Array(base64List);
                  }} />

              </div>

            </Col>
          </Row>

          <Row>
            <Col gutter={24}>
              {!!success && <Alert type="success" onClose={onDismiss} message={success} />}
              {!!alert && <Alert type="danger" onClose={onDismiss} message={error} />}
            </Col>
          </Row>
        </Input.Group>
      </Form>
    </Card>

  )

}