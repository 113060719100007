import React, { useState } from 'react';
import {
  Container,
  Row,
  Card,
  CardHeader,

  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal
} from 'reactstrap';
import { Table, Card as CardAnt, Modal as ModalAnt, Dropdown, Space, Button, Tag } from 'antd'
import { ExclamationCircleOutlined, EllipsisOutlined, DownOutlined, SmileOutlined, CheckCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'

import Header from "../components/Headers/Header.jsx";
import CharacteristicComponent from '../components/Characteristic/Characteristic';
import { getCharacteristics, deleteCharacteristic } from '../apollo/server';
//import { withTranslation } from 'react-i18next';
import { Query, Mutation } from '@apollo/client/react/components';
import { gql, useMutation, useQuery } from '@apollo/client';

import { transformToNewline } from '../utils/stringManipulations'

const GET_CHARACTERISTICS = gql`${getCharacteristics}`
const DELETE_CHARACTERISTIC = gql`${deleteCharacteristic}`

export default function Characteristic(props) {

  const [characteristic, setCharacteristic] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const { loading: getLoading, error: getError, data: getData } = useQuery(GET_CHARACTERISTICS)
  const [deleteCharacteristic, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(DELETE_CHARACTERISTIC)


  const toggleModal = (characteristic) => {
    console.log('modal togled')
    console.log(characteristic)
    setEditModal(!editModal)
  }

  const toggleDeleteModal = (characteristic) => {
    setDeleteModal(!deleteModal)
    setCharacteristic(characteristic)
  }

  const onCompleted = ({ deleteCharacteristic }) => {
    console.log(deleteCharacteristic)
  }
  const onError = (error) => {
    console.log(error)
  }
  const update = (proxy, { data: { deleteCharacteristic } }) => {
    try {
      if (deleteCharacteristic) {
        const data = proxy.readQuery({ query: GET_CHARACTERISTICS });
        data.characteristics = data.characteristics.filter(characteristic => characteristic._id !== deleteCharacteristic);
        proxy.writeQuery({ query: GET_CHARACTERISTICS, data });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const columns = [
    {
      title: 'Icono',
      width: 40,
      dataIndex: 'icon',
      key: 'icon',
      //fixed: 'left',
      render: (text, record, index) => (text && typeof text === 'string' &&
        <img alt="..."
          className=" picture-circle icon-image-upload"
          src={text} />
      )
    },
    {
      title: 'Título',
      //width: 50,
      dataIndex: 'title',
      key: 'title',
      //fixed: 'left',
    },
    {
      title: 'Activo?',
      width: 50,
      dataIndex: 'is_enabled',
      key: 'is_enabled',
      render: (text) => (text == 0 ? <PauseCircleOutlined style={{ fontSize: 20, color:"red" }} /> : <CheckCircleOutlined style={{ fontSize: 20, color: "green" }}/>)
      //fixed: 'left',
    },
    {
      title: 'Acciones',
      key: 'operation',
      //fixed: 'right',
      width: 50,
      render: (_, record) => <LineActions record={record} />
    },
  ]

  const LineActions = (props) => (
    <Dropdown
      menu={{
        items: [{
          key: '1',
          label: (
            <a href="#zoom" onClick={(e) => {
              e.preventDefault()
              console.log('togle record')
              console.log(props.record)
              toggleModal(props.record)
              setCharacteristic(props.record)
              
            }}>
              Editar
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a href="#zoom" onClick={(e) => {
              e.preventDefault()
              toggleDeleteModal(props.record)
            }}>Eliminar</a>
          )
        },]
      }}
    >
      <a href="#zoom" onClick={(e) => e.preventDefault()}>
        <Space>
          <EllipsisOutlined style={{ fontSize: '22px' }} rotate={90} />
        </Space>
      </a>
    </Dropdown>
  )

  if (getLoading) return 'Cargando...';
  //if (getError) return `Error! ${getError.message}`;

  return (
    <>
      
      {/* Page content */}
      <Container className="mt--7" fluid>

        <CharacteristicComponent />

        <Row className="mt-5">
          <div className="col">

            <CardAnt className="shadow" title="Características">

              {getError && `Error! ${getError.message}`}
              {getData.characteristics == null && <tr><td>Sin registros</td></tr>}

              <Table
                columns={columns}
                dataSource={getData.characteristics}
              />

            </CardAnt>
          </div>
        </Row>
        {/*
                <Modal
                    className="modal-dialog-centered"
                    size="lg"
                    isOpen={editModal}
                    toggle={() => { toggleModal() }}
                >
                    <CharacteristicComponent characteristic={characteristic} />
                </Modal>
                */}

        <ModalAnt
          title="Editar Característica"
          centered
          open={editModal}
          onCancel={() => setEditModal(false)}
          width={800}
          footer={[
            <Button key="cancel" onClick={() => setEditModal(false)}>
              Cancelar
            </Button>
          ]}
        >
          <CharacteristicComponent characteristic={characteristic} />
        </ModalAnt>

        <ModalAnt
          title="Eliminar Característica"
          centered
          open={deleteModal}
          onOk={() => deleteCharacteristic({ variables: { id: characteristic._id } })} //setDeleteModal(false)}
          onCancel={() => setDeleteModal(false)}
          width={800}
        >
          <p>Confirma si deseas eliminar la Característica. Esta también se eliminará de las Propiedades Asignadas.</p>

          {mutationLoading && 'Enviando...'}
          {mutationError && `error! ${mutationError.message}`}

        </ModalAnt>

      </Container>
    </>
  )

}
