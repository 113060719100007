import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Alert,
  Select
} from 'antd';
//import { PlusOutlined } from '@ant-design/icons';
import { gql, useQuery, useMutation } from '@apollo/client';
import { getUsers, getPlans } from '../apollo/server';
import { GET_MEMBERSHIPS, UPDATE_MEMBERSHIP, CREATE_MEMBERSHIP } from '../apollo/membership';
import { validateFunc } from '../constraints/constraints';

const { Option } = Select;

//const GET_MEMBERSHIPS = gql`${getMemberships}`;
//const CREATE_MEMBERSHIP = gql`${createMembership}`;
//const UPDATE_MEMBERSHIP = gql`${editMembership}`;
const GET_USERS = gql`${getUsers}`;
const GET_PLANS = gql`${getPlans}`;



export default function Membership(props) {
  const { data: userData } = useQuery(GET_USERS);
  const { data: planData } = useQuery(GET_PLANS);

  const [membership, setMembership] = useState({
    _id: '',
    user: '',
    plan: '',
    startDate: '',
    endDate: '',
    status: '',
    userError: false,
    planError: false,
    startDateError: false,
    endDateError: false,
    statusError: false
  });

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (props.membership) {
      setMembership({
        ...membership,
        ...props.membership,
      });
    }
  }, [props.membership]);

  const onChange = (value, field) => {
    setMembership({
      ...membership,
      [field]: value
    });
  };

  const validate = () => {
    const { user, plan, startDate, endDate, status } = membership;
    const userError = !!validateFunc({ membership_user: user }, 'membership_user');
    const planError = !!validateFunc({ membership_plan: plan }, 'membership_plan');
    setMembership({
      ...membership,
      userError,
      planError
    });
    if (!userError && !planError)
      return true;
    return false;
  };

  const onCompleted = ({ createMembership, editMembership }) => {
    if (createMembership) {
      setMembership({
        _id: '',
        user: '',
        plan: '',
        startDate: '',
        endDate: '',
        status: '',
        userError: false,
        planError: false,
        startDateError: false,
        endDateError: false,
        statusError: false
      });
      setSuccess('Guardado');
      setError('');
    }
    if (editMembership) {
      setSuccess('Guardado');
      setError('');
    }
  };

  const onError = (error) => {
    setSuccess('');
    setError('Un error ocurrió en el guardado, intenta nuevamente. ');
  };

  const update = (proxy, { data: { createMembership } }) => {
    try {
      if (createMembership) {
        const data = proxy.readQuery({ query: GET_MEMBERSHIPS });
        data.memberships = data.memberships.concat(createMembership);
        proxy.writeQuery({ query: GET_MEMBERSHIPS, data });
        if (props.updateMembershipList)
          props.updateMembershipList(createMembership.map(({ _id }) => _id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDismiss = () => {
    setSuccess('');
    setError('');
  };

  const [saveMembership, { loading }] = useMutation(props.membership ? UPDATE_MEMBERSHIP : CREATE_MEMBERSHIP, {
    onCompleted,
    onError,
    update,
    refetchQueries: [{ query: GET_MEMBERSHIPS }]
  });

  return (
    <Card title={props.membership ? null : "Crear Membresía"}>
      <Form>
        <Input.Group size="large">
          <Row gutter={24}>
            <Col span={8}>
              <label className="form-control-label" htmlFor="input-user">
                Usuario
              </label>
              <Select
                id="input-user"
                placeholder="Selecciona un usuario"
                value={membership.user}
                onChange={value => onChange(value, 'user')}
              >
                {userData?.users.map(user => (
                  <Option key={user._id} value={user._id}>{user.name}</Option>
                ))}
              </Select>
              {membership.userError && <p className="text-red">{validateFunc({ membership_user: membership.user }, 'membership_user')}</p>}
            </Col>

            <Col span={8}>
              <label className="form-control-label" htmlFor="input-plan">
                Plan
              </label>
              <Select
                id="input-plan"
                placeholder="Selecciona un plan"
                value={membership.plan}
                onChange={value => onChange(value, 'plan')}
              >
                {planData?.plans.map(plan => (
                  <Option key={plan._id} value={plan._id}>{plan.name}</Option>
                ))}
              </Select>
              {membership.planError && <p className="text-red">{validateFunc({ membership_plan: membership.plan }, 'membership_plan')}</p>}
            </Col>

            <Col span={8}>
              <label className="form-control-label" htmlFor="input-status">
                Estado
              </label>
              <Select
                id="input-status"
                placeholder="Selecciona un estado"
                value={membership.status}
                onChange={value => onChange(value, 'status')}
              >
                <Option value="activa">Activa</Option>
                <Option value="inactiva">Inactiva</Option>
                <Option value="cancelada">Cancelada</Option>
                <Option value="pendiente">Pendiente</Option>
                <Option value="vencida">Vencida</Option>
              </Select>
              {membership.statusError && <p className="text-red">{validateFunc({ membership_status: membership.status }, 'membership_status')}</p>}
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <label className="form-control-label" htmlFor="input-startDate">
                Fecha de Inicio
              </label>
              <Input
                id="input-startDate"
                placeholder="e.g. 2024-01-01"
                type="date"
                value={membership.startDate}
                onChange={event => onChange(event.target.value, 'startDate')}
                onBlur={() => setMembership({ ...membership, startDateError: !!validateFunc({ membership_startDate: membership.startDate }, 'membership_startDate') })}
              />
              {membership.startDateError && <p className="text-red">{validateFunc({ membership_startDate: membership.startDate }, 'membership_startDate')}</p>}
            </Col>

            <Col span={8}>
              <label className="form-control-label" htmlFor="input-endDate">
                Fecha de Fin
              </label>
              <Input
                id="input-endDate"
                placeholder="e.g. 2024-12-31"
                type="date"
                value={membership.endDate}
                onChange={event => onChange(event.target.value, 'endDate')}
                onBlur={() => setMembership({ ...membership, endDateError: !!validateFunc({ membership_endDate: membership.endDate }, 'membership_endDate') })}
              />
              {membership.endDateError && <p className="text-red">{validateFunc({ membership_endDate: membership.endDate }, 'membership_endDate')}</p>}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Button
                type="primary"
                size="large"
                block
                disabled={loading}
                onClick={async () => {
                  if (validate()) {
                    await saveMembership({
                      variables: {
                        membershipInput: {
                          _id: props.membership ? props.membership._id : '',
                          user: membership.user,
                          plan: membership.plan,
                          startDate: membership.startDate,
                          endDate: membership.endDate,
                          status: membership.status
                        }
                      }
                    });
                  }
                }}
              >
                {loading ? 'Guardando' : 'Guardar'}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {!!success && <Alert type="success" onClose={onDismiss} message={success} />}
              {!!error && <Alert type="danger" onClose={onDismiss} message={error} />}
            </Col>
          </Row>
        </Input.Group>
      </Form>
    </Card>
  );
}
