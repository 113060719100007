import { React } from "react";
import {
  Col,
} from "reactstrap";

function Home() {

  return (
    <>
      <Col lg="12" md="7" className="home-welcome-text">
      <small>Bienvenidos a Noveporta</small>
      </Col>
    </>
  )
}

export default Home;
