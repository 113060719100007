import { Select } from 'antd';
import { useState } from 'react';
import { useQuery } from '@apollo/client';

const useConditionalQuery = (query, options) => {
    const { skip } = options || {};

    const { loading, error, data, refetch } = useQuery(query, {
        ...options,
        skip: !!skip,
    });

    const executeQuery = async () => {
        if (!skip) {
            await refetch();
        }
    };

    return {
        loading,
        error,
        data,
        executeQuery, // Manual execute
    };
};

const SearchSelect = (props) => {

    const { query, data: initialData } = props;
    const { loading, error, data } = useConditionalQuery(query);

    if (query && loading) return <Select placeholder = '...cargando lista' />

    let options = [];
    if (query) {
        console.log('data')
        console.log(data)
        //console.log(data['characteristics'])
        const list = data[props.query_name] || [];
        //    const list = data?.options || [];
        options = list.map((item) => ({ label: item[props.label], value: item[props.value], data: item }));

    } else {
        options = initialData.map((item) => ({ label: item[props.label], value: item[props.value], data: item }));
    }

    const handleChange = (value, option) => {
        const selectedData = option?.data || options.find((item) => item.value === value)?.data;
        console.log('seleted data')
        console.log(selectedData)
        
        props.onChange(selectedData);
    };

    // Its ok label and value
    console.log(props.label)
    console.log(props.value)
    // Options returns []
    console.log('SELECT OPTIONS',options)

    return (

        <Select
            showSearch
            style={{ width: 200 }}
            placeholder={props.placeholder ? props.placeholder : "Selecciona un texto"}
            optionFilterProp="children"
            loading={loading}
            error={error}
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={options}
            onSelect={handleChange}


        />
    )
};

export default SearchSelect;
