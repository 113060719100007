import React, { useEffect, useState } from "react";
import { Route, Outlet, useLocation, NavLink, Link } from "react-router-dom";
// reactstrap components

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Container, Card, Layout, Menu, theme, Image, Row, Col, Dropdown, Space } from 'antd';

// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";

import routes from "../routes.js";

const handleButtonClick = (e) => {
  //message.info('Click on left button.');
  //console.log('click left button', e);
};
const handleMenuClick = (e) => {
  //message.info('Click on menu item.');
  console.log('click', e);
};

const items = [
  {
    label: 'Salir',
    key: '1',
    icon: <UserOutlined />,
  },
]
const menuProps = {
  items,
  onClick: handleMenuClick,
};

const { Header, Sider, Content } = Layout;

export default function Admin() {

  const [collapsed, setCollapsed] = useState(false);
  const { token: { colorBgContainer }, } = theme.useToken();

  const location = useLocation();
  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  }

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //this.refs.mainContent.scrollTop = 0; 
  })

  return (
    <>
      <Layout>
        <Sider theme="dark" trigger={null} collapsible collapsed={collapsed}>

          <Row justify="space-around" >
            <Col>
              <Image
                style={{ maxWidth: 100, padding: '10px' }}
                src={require("../assets/img/brand/logo.png")}
              />
            </Col>
          </Row>


          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['1']}
            items={[
              {
                key: 'panel',
                icon: <VideoCameraOutlined />,
                label: 'Panel',
              },
              {
                key: 'property',
                icon: <VideoCameraOutlined />,
                label: (
                  <Link
                    to='/admin/property'>
                    Propiedades
                  </Link>
                ),
              },
              {
                key: 'characteristics',
                icon: <VideoCameraOutlined />,
                label: (
                  <Link
                    to='/admin/characteristic'>
                    Características
                  </Link>
                ) ,
              },
              {
                key: 'membership',
                icon: <VideoCameraOutlined />,
                label: (
                  <Link
                    to='/admin/membership'>
                    Membresías
                  </Link>
                ),
              },
              {
                key: 'plan',
                icon: <VideoCameraOutlined />,
                label: (
                  <Link
                    to='/admin/plan'>
                    Planes
                  </Link>
                ),
              },
              {
                key: '3',
                icon: <UserOutlined />,
                label: (
                  <Link
                    to='/admin/users'>
                    Usuarios
                  </Link>
                ),
              },
              {
                key: '4',
                icon: <UploadOutlined />,
                label: 'Configuración',
              },
            ]}
          />
        </Sider>
        <Layout className="site-layout">
          <Header
            style={{
              padding: 0,
              background: 'none',
            }}
          >

            <Row>
              <Col flex="none">
                <div
                  style={{
                    padding: '0 16px',
                  }}
                >
                  {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: () => setCollapsed(!collapsed),
                  })}
                </div>
              </Col>
              <Col flex="auto"></Col>
              <Col flex="none">
                <div
                  style={{
                    padding: '0 16px',
                  }}
                >
                  <Space style={{ marginRight: '50px' }}>
                    <Dropdown.Button menu={menuProps} placement="bottomRight" icon={<UserOutlined />}>
                      Admin
                    </Dropdown.Button>
                  </Space>
                </div>
              </Col>


            </Row>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              //background: colorBgContainer,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>

      {/*
      <Sidebar
        routes={routes}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: require("../assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content">

        <AdminNavbar
          brandText={getBrandText(location.pathname)}
        />
        
        <Outlet />

        <Container fluid>
          {/*<AdminFooter />}
        </Container>
      </div>
      */}
    </>
  )
} 