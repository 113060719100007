export const getPlans = `
    query Plans{
        plans {
            _id
            name
            price
            interval
            description
            images_qty
            properties_qty
            stripePlanId
            stripeProductId
            is_deleted
            createdAt
            updatedAt 
        }
    }`

export const editPlan = `
  mutation EditPlan($planInput:PlanInput!) {
    editPlan(planInput: $planInput){
        _id
        name
        price
        interval
        description
        images_qty
        properties_qty
        stripePlanId
        stripeProductId
        is_deleted
        createdAt
        updatedAt  
      }
    }`

export const createPlan = `
  mutation CreatePlan($planInput: PlanInput!) {
    createPlan(planInput: $planInput){
        _id
        name
        price
        interval
        description
        images_qty
        properties_qty
        stripePlanId
        stripeProductId
        is_deleted
        createdAt
        updatedAt 
    }
  }`

export const deletePlan = `
      mutation DeletePlan($id:String!){
        deletePlan(id:$id){
          _id
        }
      }`



export const getProperties = `query Properties{
    properties{
      _id
      title
      short_description
      description
      likes
      images
      price
      priceDesc
      user {
        _id
        name
        phone
        email
        password
        picture
        is_active
        createdAt
        updatedAt
        notificationToken
      } 
      liked
      is_active
      is_enabled
      is_reserved
      characteristics {
        _id
        qty
        characteristic {
        	title
          icon
      	}
      }
      maps_location {
        address
        lat
        lng
      }
    }
  }`
  
export const createProperty = `
  mutation CreateProperty($propertyInput:PropertyInput!){
      createProperty(
          propertyInput:$propertyInput
      ){
      _id
      title
      short_description
      description
      likes
      images
      user {
        _id
        name
      } 
      liked
      is_active
      is_enabled
      is_reserved
      characteristics {
        _id
        characteristic {
            title
            icon
      	  }
        qty
      }
      maps_location {
        address
        lat
        lng
      }
    }
  }`

export const editProperty = `
    mutation EditProperty($propertyInput:PropertyInput!){
      editProperty(
          propertyInput:$propertyInput
      ){
        _id
        title
        short_description
        description
        likes
        images
        user {
          _id
          name
        }
        liked
        is_active
        is_enabled
        is_reserved
        characteristics {
          characteristic {
            title
            icon
      	  }
          _id
          qty
        }
        maps_location {
          address
          lat
          lng
        }
      }
    }`

export const deleteProperty = `
      mutation DeleteProperty($id:String!){
        deleteProperty(id:$id){
          _id
        }
      }`

export const getCategories = `query Categories{categories{
        _id
        title
        description
        img_menu
      }}`



export const createCategory = `
mutation CreateCategory($title:String!,$description:String!,$img_menu:String){
  createCategory(category:{title:$title,description:$description,img_menu:$img_menu}){_id}
}`


export const editCategory = `
      mutation EditCategory( $_id:String,$title:String!,$description:String!,$img_menu:String){
        editCategory(category:{_id:$_id,title:$title,description:$description,img_menu:$img_menu}){_id}
      }`

export const deleteCategory = `
      mutation DeleteCategory($id:String!){
        deleteCategory(id:$id){
          _id
        }
      }`

export const membership = `
  query {
    membership($id:String) {
        id
        user {
          id
          name
        }
        plan {
          id
          name
        }
      }
    }
  `

export const getOrders = `query Orders($page:Int){
  allOrders(page:$page){
    _id
    delivery_address{
      latitude
      longitude
      delivery_address
      details
      label
    }
    delivery_charges
    order_amount
    paid_amount
    payment_method
    order_id
    user{
      _id
      name
      email
      phone
    }
    items{
      _id
      food{
        _id
        title
        description
        img_url
      }
      variation{
        _id
        title
        price
        discounted
      }
      addons{
        _id
        title
        description
        quantity_minimum
        quantity_maximum
        options{
          _id
          title
          price
        }
      }
      quantity
    }
    reason
    status
    payment_status
    order_status
    createdAt
    review{
      _id
      rating
      description
    }
    rider{
      _id
      name
    }
  }
}`

export const getDashboardTotal = `query GetDashboardTotal($startingDate: String, $endingDate: String){
  getDashboardTotal(starting_date: $startingDate, ending_date: $endingDate){
    total_orders
    total_users
    total_sales
  }
}`
export const getDashboardSales = `query GetDashboardSales($startingDate: String, $endingDate: String){
  getDashboardSales(starting_date: $startingDate, ending_date: $endingDate){
    orders{
      day
      amount
    }
  }
}`
export const getDashboardOrders = `query GetDashboardOrders($startingDate: String, $endingDate: String){
  getDashboardOrders(starting_date: $startingDate, ending_date: $endingDate){
    orders{
      day
      count
    }
  }
}`

export const getDashboardData = `query GetDashboardData($startingDate: String, $endingDate: String){
  getDashboardData(starting_date: $startingDate, ending_date: $endingDate){
    total_orders
    total_users
    total_sales
    orders{
      day
      count
      amount
    }
  }
}`

export const getConfiguration = `query GetConfiguration{
  configuration{
    _id
    order_id_prefix
    email
    password
    enable_email
    client_id
    client_secret
    sandbox
    publishable_key
    secret_key
    delivery_charges
    currency
    currency_symbol
  }
}`

export const saveOrderConfiguration = `mutation SaveOrderConfiguration($configurationInput:OrderConfigurationInput!){
  saveOrderConfiguration(configurationInput:$configurationInput){
    _id
    order_id_prefix
  }
}`
export const saveEmailConfiguration = `mutation SaveEmailConfiguration($configurationInput:EmailConfigurationInput!){
  saveEmailConfiguration(configurationInput:$configurationInput){
    _id
    email
    password
    enable_email
  }
}`
export const saveMongoConfiguration = `mutation SaveMongoConfiguration($configurationInput:MongoConfigurationInput!){
  saveMongoConfiguration(configurationInput:$configurationInput){
    _id
    mongodb_url
  }
}`

export const savePaypalConfiguration = `mutation SavePaypalConfiguration($configurationInput:PaypalConfigurationInput!){
  savePaypalConfiguration(configurationInput:$configurationInput){
    _id
    client_id
    client_secret
    sandbox
  }
}`

export const saveStripeConfiguration = `mutation SaveStripeConfiguration($configurationInput:StripeConfigurationInput!){
  saveStripeConfiguration(configurationInput:$configurationInput){
    _id
    publishable_key
    secret_key
  }
}`
export const saveDeliveryConfiguration = `mutation SaveDeliveryConfiguration($configurationInput:DeliveryConfigurationInput!){
  saveDeliveryConfiguration(configurationInput:$configurationInput){
    _id
    delivery_charges
  }
}`
export const saveCurrencyConfiguration = `mutation SaveCurrencyConfiguration($configurationInput:CurrencyConfigurationInput!){
  saveCurrencyConfiguration(configurationInput:$configurationInput){
    _id
    currency
    currency_symbol
  }
}`

export const adminLogin = `mutation AdminLogin($email:String!,$password:String!){
  adminLogin(email:$email,password:$password){
    userId
    token
    name
    email
  }
}`

export const updateOrderStatus = `mutation UpdateOrderStatus($id:String!,$status:String!){
  updateOrderStatus(id:$id,status:$status){
    _id
    order_status
  }
}
`
export const updateStatus = `mutation UpdateStatus($id:String!,$status:Boolean!,$reason:String){
  updateStatus(id:$id,status:$status,reason:$reason){
    _id
    status
    reason
  }
}
`

export const uploadToken = `mutation UploadToken($pushToken:String!){
  uploadToken(pushToken:$pushToken){
    _id
    push_token
  }
}`

export const getUsers = `query{
  users{
    _id
    name
    email
    phone
    role {
      _id
      key
      name
    }
    picture
    is_active
  }
}`

export const getUserSearch = `
  query UserSearch($searchValue:String!){
    userSearch(searchValue: $searchValue) {
       _id
      name
      email
      phone
      role {
        _id
        key
        name
      }
      picture
      is_active
    }
  }
`;

export const resetPassword = `mutation ResetPassword($password:String!,$token:String!){
  resetPassword(password:$password,token:$token){
    result
  }
}`

export const createRider = `
mutation CreateRider($riderInput:RiderInput!){
    createRider(
        riderInput:$riderInput
    ){
    _id
    name
    username
    password
    phone
    image
    available
    }
  }`

export const getRiders = `query{
  riders{
    _id
    name
    username
    password
    phone
    image
    available
  }
}`

export const getAvailableRiders = `query{
  availableRiders{
    _id
    name
    username
    phone
    image
    available
  }
}`

export const editRider = `
    mutation EditRider($riderInput:RiderInput!){
        editRider(
          riderInput:$riderInput
        ){
          _id
          name
          username
          phone
          image
        }
      }`
export const deleteRider = `
      mutation DeleteRider($id:String!){
        deleteRider(id:$id){
          _id
        }
      }`

export const toggleAvailablity = `
      mutation ToggleRider($id:String){
        toggleAvailablity(id:$id){
          _id
        }
}`

export const pageCount = `
query{
  pageCount
}
`

export const assignRider = ` mutation AssignRider($id:String!,$riderId:String!){
  assignRider(id:$id,riderId:$riderId){
    _id
    rider{
      _id
      name
    }
  }
}`

export const getOrderStatuses = `query{
  getOrderStatuses
}
`

export const getPaymentStatuses = `query{
  getPaymentStatuses
}`

export const updatePaymentStatus = `mutation UpdatePaymentStatus($id:String!,$status:String!){
  updatePaymentStatus(id:$id,status:$status){
    _id
    payment_status
    paid_amount
  }
}
`

export const createOptions = `mutation CreateOptions($optionInput:[OptionInput]){
  createOptions(optionInput:$optionInput){
    _id
    title
    description
    price
  }
}`

export const getOptions = `query Options{
  options {
    _id
    title
    description
    price
  }
}
`

export const createCharacteristic = `mutation CreateCharacteristic($characteristicInput:CharacteristicInput){
  createCharacteristic(characteristicInput:$characteristicInput){
    _id
    title
    icon
    is_enabled
  }
}`

export const editCharacteristic = `mutation EditCharacteristic($characteristicInput:CharacteristicInput){
  editCharacteristic(characteristicInput:$characteristicInput){
    _id
    title
    icon
    is_enabled
  }
}`

export const getCharacteristics = `query Characteristic{
  characteristics{
    _id
    title
    icon
    is_enabled
  }
}`

export const deleteCharacteristic = `
  mutation DeleteCharacteristic($id:String!){
    deleteCharacteristic(id:$id)
  }`

export const deleteOption = `
      mutation DeleteOption($id:String!){
        deleteOption(id:$id)
      }`
export const editOption = `mutation editOption($optionInput:OptionInput){
  editOption(optionInput:$optionInput){
          _id
          title
          description
          price
        }
      }`

export const createCoupon = `mutation CreateCoupon($couponInput:CouponInput!){
  createCoupon(couponInput:$couponInput){
    _id
    code
    discount
    enabled
  }
}`
export const editCoupon = `mutation editCoupon($couponInput:CouponInput!){
  editCoupon(couponInput:$couponInput){
    _id
    code
    discount
    enabled
        }
      }`
export const deleteCoupon = `mutation DeleteCoupon($id:String!){
        deleteCoupon(id:$id)
      }`


export const getCoupons = `query Coupons{
        coupons {
          _id
          code
          discount
          enabled
        }
      }`