import React from "react";
import { Card, CardHeader, CardBody, Col } from "reactstrap";

function Privacidad() {
  return (
    <>
      <Col lg="12" md="12">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted mt-2 mb-3">
              <h2>Aviso de Privacidad y Cancelaciones</h2>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <p>
              Al utilizar Noveporta App, aceptas los siguientes términos y condiciones que rigen el acceso y uso de nuestros servicios. Estos términos son vinculantes para todos los usuarios de la aplicación y cubren aspectos relacionados con la administración de cuentas, el manejo de información personal, transacciones financieras, y otros contenidos generados por el usuario dentro de la plataforma. Te recomendamos leer cuidadosamente estos términos para comprender los derechos y responsabilidades al utilizar Noveporta.
            </p>

            <h3>Información Personal Recopilada</h3>
            <p>
              Al registrarte y usar Noveporta, recopilamos ciertos datos personales necesarios para el funcionamiento de la aplicación. Estos datos se utilizan exclusivamente para brindarte una mejor experiencia de usuario, administrar tu cuenta y mejorar nuestros servicios. Los datos que recopilamos incluyen:
            </p>
            <ul>
              <li><strong>Nombre:</strong> Necesario para la administración de tu cuenta y personalización de la experiencia de usuario.</li>
              <li><strong>Dirección de correo electrónico:</strong> Utilizada para el acceso a tu cuenta, comunicaciones importantes y notificaciones del servicio.</li>
              <li><strong>ID de usuario:</strong> Se emplea para identificar de manera única tu cuenta dentro de la plataforma.</li>
              <li><strong>Dirección (Opcional):</strong> Puede ser utilizada para propósitos relacionados con facturación y entrega de ciertos servicios o productos.</li>
            </ul>

            <h3>Información Financiera</h3>
            <p>
              <strong>Historial de compras:</strong> Al realizar transacciones en Noveporta, almacenamos un registro de las compras realizadas para la correcta administración de pagos y suscripciones.
            </p>

            <h3>Comunicación y Mensajes</h3>
            <ul>
              <li><strong>Correos electrónicos:</strong> Utilizamos tu dirección de correo electrónico para enviarte notificaciones, información relevante sobre tu cuenta y actualizaciones importantes.</li>
              <li><strong>Otros mensajes desde la app:</strong> Los mensajes que envías a través de la aplicación son parte integral de nuestra plataforma y se gestionan para mejorar la interacción entre usuarios y el soporte técnico.</li>
            </ul>

            <h3>Fotos y Videos</h3>
            <p>
              <strong>Fotos:</strong> Las imágenes subidas por el usuario se almacenan y utilizan únicamente dentro de las funciones previstas por la aplicación.
            </p>

            <h3>Actividad en la App</h3>
            <ul>
              <li><strong>Otro contenido generado por usuarios:</strong> Todo el contenido que generes en Noveporta, como comentarios o interacciones, será utilizado para mejorar la experiencia en la plataforma.</li>
              <li><strong>Otras acciones:</strong> Funciones de la app.</li>
            </ul>

            <h3>Dispositivos y Datos de Uso</h3>
            <ul>
              <li><strong>Dispositivo u otros ID:</strong> Se recopilan identificadores únicos de tu dispositivo para gestionar la seguridad de la cuenta y optimizar el rendimiento de la aplicación.</li>
            </ul>

            <h3>Con quién compartimos tus datos</h3>
            <p>
              Noveporta se compromete a proteger tu privacidad y no compartir tus datos personales con terceros, a menos que sea estrictamente necesario para cumplir con obligaciones legales o cuando tú explícitamente lo autorices. Por ejemplo, si solicitas un restablecimiento de contraseña, tu dirección IP será incluida en el correo de restablecimiento.
            </p>

            <h3>Eliminación de Cuenta y Datos Asociados</h3>
            <p>
              En <strong>Noveporta</strong>, respetamos tu privacidad y te brindamos el control sobre tus datos. Si deseas eliminar tu cuenta y los datos asociados, sigue las instrucciones a continuación:
            </p>
            <ol>
              <li><strong>Instrucciones para Solicitar la Eliminación de la Cuenta:</strong>
                <ul>
                  <li>Opción 1. Visita la página de eliminación para las instrucciones dentro de la App: <a href="/cancelaciones">Eliminación de Cuenta en App</a>.</li>
                  <li>Opción 2. Envíanos un mensaje con tu correo electrónico registrado a <a href="mailto:contacto@noveporta.com">contacto@noveporta.com</a></li>
                </ul>
              </li>

              <li><strong>Datos que se Eliminarán:</strong>
                <ul>
                  <li>Información personal (nombre, dirección de correo electrónico, ID de usuario, dirección).</li>
                  <li>Historial de compras.</li>
                  <li>Correos electrónicos y otros mensajes desde la app.</li>
                  <li>Fotos y otro contenido generado por usuarios.</li>
                  <li>Dispositivo u otros ID.</li>
                </ul>
              </li>

              <li><strong>Períodos de Retención Adicionales:</strong>
                <ul>
                  <li>Algunos datos pueden retenerse temporalmente para cumplir con requerimientos legales antes de su eliminación definitiva.</li>
                </ul>
              </li>

              <li><strong>Solicitudes de Eliminación Parcial de Datos:</strong>
                <ul>
                  <li>Si deseas solicitar la eliminación parcial de tus datos sin eliminar tu cuenta, puedes contactarnos a través de <a href="mailto:contacto@noveporta.com">contacto@noveporta.com</a>.</li>
                </ul>
              </li>
            </ol>

            <p><strong>Nota:</strong> La eliminación de tu cuenta es irreversible. Una vez eliminada, no podrás recuperar tu cuenta ni los datos asociados.</p>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default Privacidad;
