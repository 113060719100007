import React from "react";
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
//import { ApolloClient } from 'apollo-client';
//import { ApolloProvider } from "react-apollo";
//import gql from "graphql-tag";
import { createUploadLink } from 'apollo-upload-client';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
//import * as firebase from "firebase/app";
//import "firebase/messaging";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import { getOrders, uploadToken } from "../src/apollo/server";

import reportWebVitals from './reportWebVitals';

import { server_url } from "./config/config";
import App from './App'
const GET_ORDERS = gql`${getOrders}`
const UPLOAD_TOKEN = gql`${uploadToken}`
/*
const firebaseConfig = {
  apiKey: "AIzaSyDCnSTWqbN7NWg9oVDzWz5dvhw2dX-RTb0",
  authDomain: "foodapp-77e88.firebaseapp.com",
  databaseURL: "https://foodapp-77e88.firebaseio.com",
  projectId: "foodapp-77e88",
  storageBucket: "foodapp-77e88.appspot.com",
  messagingSenderId: "678143951107",
  appId: "1:678143951107:web:498eca9a1eca6c0b"
};*/
const firebaseConfig = {
  apiKey: "AIzaSyAU8NKrmocvB1jwQPAQ-7ZxhLZPmzgEs9Q",
  authDomain: "noveporta-ab704.firebaseapp.com",
  projectId: "noveporta-ab704",
  storageBucket: "noveporta-ab704.appspot.com",
  messagingSenderId: "813278636296",
  appId: "1:813278636296:web:ebe195093293487240e4bd",
  measurementId: "G-5VF98FTG7E"
};

const cache = new InMemoryCache()
const httpLink = createUploadLink({
  uri: `${server_url}graphql`,
})

const client = new ApolloClient({
  link: httpLink,
  cache
});

// // Initialize Firebase
//const analytics = firebase.getAnalytics(App);
//const appfirebase = initializeApp(firebaseConfig);
//firebase.initializeApp(firebaseConfig);
//const messaging = firebase.messaging();

const messaging = getMessaging(initializeApp(firebaseConfig));
// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
getToken(messaging, { vapidKey: 'BLaIh1ZJ9r25geIgY6bWOEYyKLaDpfY1EsPaEvwo8k51e8wPjzj9LT26QivsiZFAkUorFQ6dn23emLxxCUI5fAU' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    client.mutate({ mutation: UPLOAD_TOKEN, variables: { pushToken: currentToken } })
      .then(() => {})
      .catch(() => {})
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});


onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
  var notificationTitle = 'Tienes una nueva notificación';
  var notificationOptions = {
    body: payload.data.orderid,
    icon: 'https://www.noveporta.com/img/logo.png'
  };
  let noti = new Notification(notificationTitle, notificationOptions)
  noti.onclick = function (event) {
    event.preventDefault(); // prevent the browser from focusing the Notification's tab
    window.open('https://admin.noveporta.com');
    noti.close();
  }
  // console.log('Message received. ', payload);
  client.query({ query: GET_ORDERS, fetchPolicy: 'network-only' })
});
/*
messaging.onMessage(function (payload) {
  var notificationTitle = 'Tienes una nueva notificación';
  var notificationOptions = {
    body: payload.data.orderid,
    icon: 'https://www.noveporta.com/img/logo.png'
  };
  let nt = new Notification(notificationTitle, notificationOptions)
  nt.onclick = function (event) {
    event.preventDefault(); // prevent the browser from focusing the Notification's tab
    window.open('https://admin.noveporta.com');
    nt.close();
  }
  // console.log('Message received. ', payload);
  client.query({ query: GET_ORDERS, fetchPolicy: 'network-only' })
  //client.query({ query: GET_ORDERS, fetchPolicy: 'network-only' })
});
*/
/*
ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider >
  ,
  document.getElementById("root")
);
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider >
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();