import { validate } from 'validate.js'

const constraints = {
    confirmPassword: {
        presence: true,
        equality: "password",
        length: {
            minimum: 1,
            maximum: 20
        }
    },
    prefix: {
        presence: true,
        length: {
            minimum: 1
        }
    },
    email: {
        email: true
    },
    password: {
        presence: true,
        length: {
            minimum: 1,
            maximum: 20
        }
    },
    category: {
        presence: true,
        length: {
            minimum: 5
        }
    },
    price: {
        presence: true,
        numericality: {
            greaterThan: 0
        }
    },
    discounted: {
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    type: {
        presence: true,
        length: {
            minimum: 1,
            maximum: 6
        }
    },
    mongoUrl: {
        url: {
            scheme: ['mongodb']
        }
    },
    currencyCode: {
        presence: true,
        length: {
            minimum: 1,
            maximum: 3
        }
    },
    currencySymbol: {
        presence: true,
        length: {
            minimum: 1,
            maximum: 3
        }
    },
    reason: {
        presence: true,
        length: {
            minimum: 1,
            maximum: 30
        }
    },
    optionPrice: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    addonQuantityMinimum: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 0
        }
    },
    addonQuantityMaximum: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 1
        }
    },
    stock: {
        presence: true,
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 0
        }
    },
    code: {
        presence: true,
        length: {
            minimum: 3,
            maximum: 15
        }
    },
    discount: {
        presence: true,
        numericality: {
            greaterThan: 0,
            lessThan: 100
        }
    },
}

export const validateFunc = (value, constraint) => {
    return validate(value, { [constraint]: constraints[constraint] })
}
